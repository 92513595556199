import { downloadZip } from 'client-zip';
import { atom } from 'jotai';
import { GroupProgress } from '../DownloadProgress/GroupProgress';
import { BranchTreeNode } from '../DownloadTreeNode';
import { DownloadTask } from './DownloadTask';
import { saveBlob } from './saveBlob';

export class ZipDownloadTask implements DownloadTask {
  readonly name: string;
  readonly progress: GroupProgress;
  readonly treeNode: BranchTreeNode;

  // debug info
  _zipFinishedAt: null | number = null;

  readonly errorsAtom = atom<unknown[]>(get => {
    const errors = this.treeNode.mapFileNodes(fileNode => get(fileNode.downloader.errorAtom));
    return errors.filter(error => error !== null);
  });

  constructor(treeNode: BranchTreeNode) {
    this.name = this._getTaskNameFromTree(treeNode);
    this.progress = new GroupProgress(
      treeNode.mapFileNodes(fileNode => fileNode.downloader.progress),
    );
    this.treeNode = treeNode;
  }

  private _getTaskNameFromTree(rootTreeNode: BranchTreeNode) {
    const topLevelNodes = rootTreeNode.mapChildNodes(node => node);

    if (topLevelNodes.length === 0) {
      throw new Error('treeNode is empty');
    } else if (topLevelNodes.length === 1) {
      return topLevelNodes[0].nodeName;
    } else if (topLevelNodes.length === 2) {
      return `${topLevelNodes[0].nodeName} and ${topLevelNodes[1].nodeName}`;
    } else {
      return `${topLevelNodes[0].nodeName} and other ${topLevelNodes.length - 1} items`;
    }
  }

  async start(signal: AbortSignal) {
    const promises = this.treeNode.mapFileNodes(async fileNode => {
      const blob = await fileNode.downloader.start(signal);
      return { name: fileNode.relativePath, input: blob };
    });

    const fileInputs = await Promise.all(promises);
    const folderInputs = this.treeNode.mapFolderNodes(folderNode => {
      return { name: `${folderNode.relativePath}/` };
    });
    const downloadZipInputs = [...folderInputs, ...fileInputs];

    const zip = downloadZip(downloadZipInputs);
    const zipBlob = await zip.blob();
    this._zipFinishedAt = Date.now();

    saveBlob(zipBlob, `${this.name}.zip`);
  }
}
