import logger from '@/utils/logger';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../client';
import { gql } from '../codegen';
import { ListSystemNodesBySystemNodeIdsForDownloadQueryVariables } from '../codegen/graphql';
import { request } from '../request';

export const document = gql(`
  query ListSystemNodesBySystemNodeIdsForDownload($systemNodeIds: [ID]!) {
    findSystemNodesByIds(systemNodeIds: $systemNodeIds) {
      ... on FileNode {
        __typename
        id
        kind
        name
        parentNodeId
        fileId
      }
      ... on FolderNode {
        __typename
        id
        kind
        name
        parentNodeId
      }
      ...on LinkNode {
        __typename
        id
        kind
        name
        parentNodeId
        sourceNodeId
      }
    }
  }
`);

const operationName = 'ListSystemNodesBySystemNodeIdsForDownload';

const requestFn = async (
  variables: ListSystemNodesBySystemNodeIdsForDownloadQueryVariables,
  signal?: AbortSignal,
) => {
  logger.debug(operationName, variables);
  const response = await request({ document, variables, signal });
  logger.debug(operationName, 'succeeded');
  return response;
};

const getQueryKey = (variables: ListSystemNodesBySystemNodeIdsForDownloadQueryVariables) => [
  operationName,
  variables,
];

export const ListSystemNodesBySystemNodeIdsForDownload = {
  getQueryKey,

  request: async (
    variables: ListSystemNodesBySystemNodeIdsForDownloadQueryVariables,
    signal?: AbortSignal,
  ) =>
    queryClient.fetchQuery({
      queryFn: () => requestFn(variables, signal),
      queryKey: getQueryKey(variables),
    }),

  useQuery: (variables: ListSystemNodesBySystemNodeIdsForDownloadQueryVariables) => {
    const query = useQuery({
      queryFn: () => requestFn(variables),
      queryKey: getQueryKey(variables),
    });
    return query;
  },
};
