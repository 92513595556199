import { SPLIT_API_KEY, USER_EMAIL_NAME } from '@/constants/env';
import Cookies from 'js-cookie';

// Make sure user key isn't undefined when user doesn't have the cookie
// Otherwise, it will return `control` treatment
const USER_EMAIL = (USER_EMAIL_NAME && Cookies.get(USER_EMAIL_NAME)) || 'user';

export const config = {
  core: {
    authorizationKey: SPLIT_API_KEY,
    key: USER_EMAIL,
  },
};

export const DATASETS_ADVANCED_DOWNLOADER = 'datasets-advanced-downloader';
export const ADVANCED_UPLOADER = 'advanced-uploader';
export const DATASETS_ZIP_DOWNLOADER = 'datasets-zip-downloader';
