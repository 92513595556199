import { BranchTreeNode, FileTreeNode, FolderTreeNode } from '@/domains/download/DownloadTreeNode';
import {
  ListFilesByFileIdsForDownloadQuery,
  ListSystemNodesBySystemNodeIdsForDownloadQuery,
  SystemNodeKindInput,
} from '@/graphql/codegen/graphql';
import { ListFilesByFileIdsForDownload } from '@/graphql/queries/ListFilesByFileIds.gql';
import { ListSystemNodesByParentNodeIdForDownload } from '@/graphql/queries/ListSystemNodesByParentNodeIdForDownload.gql';
import { ListSystemNodesBySystemNodeIdsForDownload } from '@/graphql/queries/ListSystemNodesBySystemNodeIdsForDownload.gql';

export class BuildSystemNodeTreeForDownloadUseCase {
  private _rootTreeNode = new BranchTreeNode();
  private _signal?: AbortSignal;

  constructor(signal?: AbortSignal) {
    this._signal = signal;
  }

  async execute(systemNodeIds: string[], parentTreeNode = this._rootTreeNode) {
    let systemNodes: ListSystemNodesBySystemNodeIdsForDownloadQuery['findSystemNodesByIds'] = [];
    if (systemNodeIds.length !== 0) {
      const systemNodeQuery = await ListSystemNodesBySystemNodeIdsForDownload.request(
        { systemNodeIds },
        this._signal,
      );
      systemNodes = systemNodeQuery.findSystemNodesByIds ?? [];
    }

    const sourceNodeIds = systemNodes?.reduce((prev, curr) => {
      if (curr?.__typename === 'LinkNode' && curr.sourceNodeId) return [...prev, curr.sourceNodeId];
      return prev;
    }, [] as string[]);

    let sourceNodes: ListSystemNodesBySystemNodeIdsForDownloadQuery['findSystemNodesByIds'] = [];
    if (sourceNodeIds.length !== 0) {
      const sourceNodeQuery = await ListSystemNodesBySystemNodeIdsForDownload.request(
        { systemNodeIds: sourceNodeIds },
        this._signal,
      );
      sourceNodes = sourceNodeQuery.findSystemNodesByIds ?? [];
    }

    const fileIds = [...systemNodes, ...sourceNodes].reduce((prev, curr) => {
      if (curr?.__typename === 'FileNode' && curr.fileId) return [...prev, curr.fileId];
      return prev;
    }, [] as string[]);

    let files: ListFilesByFileIdsForDownloadQuery['filesByIds'] = [];
    if (fileIds.length !== 0) {
      const fileQuery = await ListFilesByFileIdsForDownload.request({ fileIds }, this._signal);
      files = fileQuery.filesByIds ?? [];
    }

    for (const systemNode of systemNodes) {
      if (!systemNode) continue;
      if (!systemNode.id) continue;
      if (!systemNode.name) continue;

      if (systemNode.__typename === 'FileNode') {
        this._handleFileNode(systemNode, files, parentTreeNode);
      } else if (systemNode.__typename === 'FolderNode') {
        await this._handleFolderNode(systemNode, parentTreeNode);
      } else if (systemNode.__typename === 'LinkNode') {
        const sourceNodeId = systemNode.sourceNodeId;
        const sourceNode = sourceNodes.find(n => n?.id === sourceNodeId);

        if (!sourceNode) continue;
        if (!sourceNode.id) continue;
        if (!sourceNode.name) continue;

        if (sourceNode.__typename === 'FileNode') {
          this._handleFileNode(sourceNode, files, parentTreeNode);
        } else if (sourceNode.__typename === 'FolderNode') {
          await this._handleFolderNode(sourceNode, parentTreeNode);
        }
      }
    }

    return this._rootTreeNode;
  }

  private async _handleFileNode(
    systemNode: NonNullable<
      ListSystemNodesBySystemNodeIdsForDownloadQuery['findSystemNodesByIds']
    >[0],
    files: NonNullable<ListFilesByFileIdsForDownloadQuery['filesByIds']>,
    parentNode: BranchTreeNode,
  ) {
    if (!systemNode) return;
    if (!systemNode.id) return;
    if (!systemNode.name) return;
    if (systemNode.__typename !== 'FileNode') return;

    const fileId = systemNode.fileId;
    const file = files.find(f => f?.id === fileId);

    if (!file) return;
    if (!file.id) return;
    if (!file.signedGetObjectDownloadUrl) return;

    const relativePath =
      parentNode instanceof FolderTreeNode
        ? `${parentNode.relativePath}/${systemNode.name}`
        : systemNode.name;

    const treeNode = new FileTreeNode({
      fileDownloadUrl: file.signedGetObjectDownloadUrl,
      fileId: file.id,
      fileSize: file.storage?.size ?? undefined,
      nodeName: systemNode.name,
      relativePath,
      systemNodeId: systemNode.id,
    });

    parentNode.addChildNode(treeNode);
  }

  private async _handleFolderNode(
    systemNode: NonNullable<
      ListSystemNodesBySystemNodeIdsForDownloadQuery['findSystemNodesByIds']
    >[0],
    parentNode: BranchTreeNode,
  ) {
    if (!systemNode) return;
    if (!systemNode.id) return;
    if (!systemNode.name) return;
    if (systemNode.__typename !== 'FolderNode') return;

    const folderNodeQuery = await this._listSystemNodesByParentNodeId(systemNode.id);

    const childNodeIds = new Set<string>();
    for (const node of folderNodeQuery) {
      if (!node) continue;
      if (!node.id) continue;
      childNodeIds.add(node.id);
    }

    const relativePath =
      parentNode instanceof FolderTreeNode
        ? `${parentNode.relativePath}/${systemNode.name}`
        : systemNode.name;

    const treeNode = new FolderTreeNode({
      nodeName: systemNode.name,
      relativePath,
      systemNodeId: systemNode.id,
    });

    parentNode.addChildNode(treeNode);
    await this.execute([...childNodeIds], treeNode);
  }

  private async _listSystemNodesByParentNodeId(parentNodeId: string) {
    const pageSize = 500;
    const nodeKinds = [
      SystemNodeKindInput.FileNode,
      SystemNodeKindInput.FolderNode,
      SystemNodeKindInput.LinkNode,
    ];

    let currentPageIndex = 0;
    let currentPageQuery = await ListSystemNodesByParentNodeIdForDownload.request(
      {
        nodeKinds,
        parentNodeId,
        pageIndex: currentPageIndex,
        pageSize,
      },
      this._signal,
    );
    const data = currentPageQuery?.listSystemNodesByParentNodeId?.data ?? [];

    while (
      currentPageQuery.listSystemNodesByParentNodeId &&
      currentPageQuery.listSystemNodesByParentNodeId.totalNumberOfPages !== null &&
      currentPageQuery.listSystemNodesByParentNodeId.totalNumberOfPages !== undefined &&
      currentPageQuery.listSystemNodesByParentNodeId.totalNumberOfPages > currentPageIndex + 1
    ) {
      currentPageIndex += 1;
      currentPageQuery = await ListSystemNodesByParentNodeIdForDownload.request(
        {
          nodeKinds,
          parentNodeId,
          pageIndex: currentPageIndex,
          pageSize,
        },
        this._signal,
      );
      data.push(...(currentPageQuery?.listSystemNodesByParentNodeId?.data ?? []));
    }

    return data;
  }
}
