import { jotaiStore } from '@/stores/jotaiStore';
import { isAbortionError } from '@/utils/errors/AbortionError';
import logger from '@/utils/logger';
import { atom } from 'jotai';
import ky, { DownloadProgress } from 'ky';
import PQueue from 'p-queue';
import { Progress } from './DownloadProgress/Progress';

export const queue = new PQueue({ interval: 1000, intervalCap: 5, concurrency: 5 });

export type DownloaderStage = 'initial' | 'downloading' | 'aborted' | 'failure' | 'success';

export class Downloader {
  private _downloadPromise: null | Promise<Blob> = null;
  private _progress: Progress;
  private _store = jotaiStore;
  readonly url: string;

  private _dataAtom = atom<null | Blob>(null);
  private _errorAtom = atom<null | unknown>(null);
  private _stageAtom = atom<DownloaderStage>('initial');

  readonly dataAtom = atom(get => get(this._dataAtom));
  readonly errorAtom = atom(get => get(this._errorAtom));
  readonly stageAtom = atom(get => get(this._stageAtom));

  constructor(url: string, contentLength?: number) {
    this._progress = new Progress(contentLength);
    this.url = url;
  }

  async start(signal?: AbortSignal) {
    const currentStage = this._store.get(this._stageAtom);

    if (currentStage === 'aborted' || currentStage === 'failure') {
      // retry
      this._downloadPromise = null;
      this._progress.reset();
      this._store.set(this._dataAtom, null);
      this._store.set(this._errorAtom, null);
      this._store.set(this._stageAtom, 'initial');
    }

    if (this._downloadPromise === null) {
      this._downloadPromise = this._download(signal);
      this._store.set(this._stageAtom, 'downloading');
    } else if (signal) {
      logger.warn('signal is ignored because the download has already been started');
    }

    try {
      const blob = await this._downloadPromise;
      return blob;
    } catch (error) {
      logger.warn(error);
      this._store.set(this._errorAtom, error);

      if (isAbortionError(error)) {
        this._store.set(this._stageAtom, 'aborted');
      } else {
        this._store.set(this._stageAtom, 'failure');
      }

      throw error;
    }
  }

  private _onProgress = (kyProgress: DownloadProgress) => {
    this._progress.update(kyProgress.transferredBytes, kyProgress.totalBytes);
  };

  private async _download(signal?: AbortSignal) {
    const downloadFn = async () => {
      const response = await ky(this.url, {
        method: 'GET',
        onDownloadProgress: this._onProgress,
        retry: 0, // disable retry
        signal,
        timeout: false, // disable auto-timeout
      });
      const blob = await response.blob();
      return blob;
    };

    const blob = await queue.add(downloadFn, { signal });
    if (!blob) throw new Error('invalid blob');

    this.progress.finish();
    this._store.set(this._dataAtom, blob);
    this._store.set(this._stageAtom, 'success');

    return blob;
  }

  get progress() {
    return this._progress;
  }
}
