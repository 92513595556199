import { Checkbox } from '@/LegacyExplore/components/Checkbox';
import {
  FilterMenu,
  SceneEntity,
  SceneEntityTree,
} from '@/LegacyExplore/components/SceneEntityTree';
import { ANALYTICS_EVENT_OBJECT } from '@/LegacyExplore/constants/analytics';
import { LAYER_FORMAT_TYPE } from '@/LegacyExplore/constants/layer';
import { DATASET_ROOT } from '@/LegacyExplore/constants/paths';
import { canPolicyActionEdit } from '@/LegacyExplore/constants/policy';
import { DeleteAnnotationsMutationVariables } from '@/LegacyExplore/graphql/codegen/graphql';
import { DELETE_ANNOTATIONS } from '@/LegacyExplore/graphql/mutations';
import { request } from '@/LegacyExplore/graphql/request';
import { useRefetchAnnotations } from '@/LegacyExplore/hooks/useFetchAnnotations';
import { useFetchProject } from '@/LegacyExplore/hooks/useFetchProject';
import { useFetchSceneEntityPermissions } from '@/LegacyExplore/hooks/useFetchSceneEntityPermissions';
import {
  ConfirmationModalState,
  DeleteConfirmationModal,
} from '@/LegacyExplore/pages/ProjectPage/DeleteConfirmationModal';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { useLayout } from '@/LegacyExplore/stores/layout';
import {
  Annotation,
  AnnotationGroup,
  Layer,
  LayerGroup,
  PhotoGroup,
  setFilteredLayerKeys,
  setFilteredTemplateKeys,
  useViewer,
} from '@/LegacyExplore/stores/viewer';
import { chunk } from '@/LegacyExplore/utils/chunk';
import { cn } from '@/LegacyExplore/utils/classname';
import { debounce } from '@/LegacyExplore/utils/debounce';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  Button,
  DropdownItem,
  Input,
  Menu,
  MenuItem,
  Dropdown as SkandDropdown,
  toast,
  TreeNodeProps,
} from '@skand/ui';
import { IfcElementNode } from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BlockLoading } from '../BlockLoading';
import { DraggableMenu } from '../DraggableMenu';
import { EmptyResourceMessage } from '../EmptyResourceMessage';
import { FindIcon } from '../IconButton';
import { AnnotationGroupNode } from './AnnotationGroupNode';
import { AnnotationNode } from './AnnotationNode';
import { CreateNode } from './CreateNode';
import { LayerGroupNode } from './LayerGroupNode';
import { LayerNode } from './LayerNode';
import {
  AnnotationGroupsMenu,
  AnnotationPermissionMenu,
  AnnotationUploadMenu,
  ClippingMenu,
  GroupSettingsMenu,
  IFCSettingsMenu,
  LinkLayerMenu,
  Mesh3DSettingsMenu,
  PointCloudSettingsMenu,
  SelectContextMenu,
} from './Menus';
import { IFCPropertiesMenu } from './Menus/IFCPropertiesMenu';
import { TilesetSettingsMenu } from './Menus/TilesetSettingsMenu';
import { PhotoGroupNode } from './PhotoGroupNode';
import { PhotoNode } from './PhotoNode';

export const SceneTab = () => {
  const layerGroups = useViewer(state => state.layerGroups);
  const layers = useViewer(state => state.layers);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const filteredTemplateKeys = useViewer(state => state.filteredTemplateKeys);
  const filteredLayerKeys = useViewer(state => state.filteredLayerKeys);
  const enabledSelectMode = useViewer(state => state.enabledSelectMode);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const enabled2D = useViewer(state => state.enabled2D);

  const annotationDraft = useExplore(state => state.annotationDraft);
  const projectId = useExplore(state => state.projectId);

  const { getSceneEntityPermission } = useFetchSceneEntityPermissions();
  const hideRightSideBar = useLayout(state => state.hideRightSideBar);
  const { fetch: fetchProject, isLoading } = useFetchProject();

  const [projectName, setProjectName] = useState('Scene');
  const [searchKey, setSearchKey] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
    cancelFunction: () => null,
  });

  const dropdownRef = useRef<HTMLLabelElement>(null);
  const [enableCreateLayer, setEnableCreateLayer] = useState(false);
  const [enableCreateAnnotation, setEnableCreateAnnotation] = useState(false);
  const [enabledFilterMenu, setEnabledFilterMenu] = useState(false);
  const [enabledEditNodeId, setEnabledEditNodeId] = useState('');
  const [enabledAnnotationGroupsMenu, setEnabledAnnotationGroupsMenu] = useState(false);
  const [enablePermissionMenu, setEnablePermissionMenu] = useState(false);
  const [permissionMenuType, setPermissionMenuType] = useState('delete');

  const { allSceneEntitiesPermission } = useFetchSceneEntityPermissions();
  const { refetch: refetchAnnotations } = useRefetchAnnotations();
  const canEdit = canPolicyActionEdit(allSceneEntitiesPermission);

  const [linkLayerId, setLinkLayerId] = useState<
    LayerGroup['id'] | Layer['id'] | AnnotationGroup['id'] | PhotoGroup['id'] | null
  >(null);
  const [layerSettingsId, setLayerSettingsId] = useState<
    LayerGroup['id'] | Layer['id'] | AnnotationGroup['id'] | PhotoGroup['id'] | null
  >(null);
  const [layerClippingId, setLayerClippingId] = useState<Layer['id'] | null>(null);
  const [annotationUploadGroupId, setAnnotationUploadGroupId] = useState<
    AnnotationGroup['id'] | null
  >(null);
  const [selectedIfcElement, setSelectedIfcElement] = useState<IfcElementNode | null>(null);
  const [selectedSceneEntities, setSelectedSceneEntities] = useState<SceneEntity[]>([]);
  const [isAddDropdownOpen, setIsAddDropdownOpen] = useState(false);

  const deleteAnnotation = useMutation({
    mutationFn: (variables: DeleteAnnotationsMutationVariables) =>
      request(DELETE_ANNOTATIONS, variables),
    onSuccess: ({ deleteAnnotations }) => {
      if (deleteAnnotations) {
        const groupIds = deleteAnnotations.map(el => el?.groupId as string);
        refetchAnnotations(groupIds);

        useViewer.setState(state => {
          for (const group of state.annotationGroups) {
            group.loadState = 'pending';
          }
          return { annotationGroups: [...state.annotationGroups] };
        });

        toast({
          message: `Successfully deleted ${deleteAnnotations.length} annotation${
            deleteAnnotations.length !== 1 ? 's' : ''
          }.`,
          lifespan: 5000,
          clickToDismiss: true,
          type: 'success',
        });
      }
    },
  });

  // Define Tree node component
  const TreeNode = useCallback(
    ({ data, isLeaf, depth, setOpen, isOpen }: TreeNodeProps<SceneEntity>) => {
      const isAnnotationSelected = annotationDraft?.annotationId === data.entity.id;
      const childCount = data.children.length;
      const isPhotoSelected = targetPhoto === data.entity && enabled2D;
      const isLayerSelected = layerSettingsId === data.entity.id;
      const selected = isAnnotationSelected || isPhotoSelected || isLayerSelected;
      const isAnnotation = data.entity.type === 'annotation' && enabledSelectMode;
      const isAnnotationGroup =
        data.entity.type === 'annotationGroup' && !!data.entity.annotations.length;
      const isChecked = isAnnotation
        ? selectedSceneEntities.includes(data)
        : data.children.every(element => selectedSceneEntities.includes(element));
      const isCheckedPartial =
        isAnnotationGroup &&
        data.children.some(element => selectedSceneEntities?.includes(element)) &&
        !isChecked;
      return (
        <div
          className={cn(
            enabledSelectMode
              ? 'bg-transparent'
              : selected && !enabledSelectMode
              ? 'bg-neutral-200 rounded-1'
              : 'bg-neutral-100',
          )}
        >
          <div
            className={cn('inline-flex flex-row items-center gap-2 py-1 pr-2 h-7')}
            style={{ paddingLeft: `${depth * 12}px` }}
          >
            {enabledSelectMode &&
              (isAnnotation || isAnnotationGroup ? (
                <div className={cn(isAnnotation && 'ml-7')}>
                  <Checkbox checked={isChecked} checkedPartial={isCheckedPartial} small />
                </div>
              ) : (
                <div className="h-3 w-3" />
              ))}

            <div
              className={cn(
                'color-neutral-400 w-3 h-3 text-3 flex-none',
                !isLeaf && 'i-skand-dropdown cursor-pointer',
                !isOpen && 'rotate-270',
                'skand-tree-row-override',
                isAnnotation && 'hidden',
              )}
              onClick={() => setOpen(!isOpen)}
            />
            {data.entity.type === 'layerGroup' && (
              <LayerGroupNode
                childCount={childCount}
                enabledEditNodeId={enabledEditNodeId}
                group={data.entity}
                openConfirmationModal={openConfirmationModal}
                setEnabledEditNodeId={setEnabledEditNodeId}
                setLinkLayerId={setLinkLayerId}
                setOpenConfirmationModal={setOpenConfirmationModal}
              />
            )}
            {data.entity.type === 'layer' && (
              <LayerNode
                childCount={childCount}
                clippingTargetId={layerClippingId}
                enabledEditNodeId={enabledEditNodeId}
                layer={data.entity}
                selected={selected}
                setEnabledEditNodeId={setEnabledEditNodeId}
                setLayerClippingId={setLayerClippingId}
                setLayerSettingsId={setLayerSettingsId}
                setLinkLayerId={setLinkLayerId}
                settingsTargetId={layerSettingsId}
              />
            )}
            {data.entity.type === 'annotation' && (
              <AnnotationNode
                annotation={data.entity}
                selected={selected}
                setOpenConfirmationModal={setOpenConfirmationModal}
              />
            )}
            {data.entity.type === 'annotationGroup' && (
              <AnnotationGroupNode
                childCount={childCount}
                enabledEditNodeId={enabledEditNodeId}
                group={data.entity}
                openConfirmationModal={openConfirmationModal}
                setAnnotationUploadGroupId={setAnnotationUploadGroupId}
                setEnabledEditNodeId={setEnabledEditNodeId}
                setLinkLayerId={setLinkLayerId}
                setOpenConfirmationModal={setOpenConfirmationModal}
              />
            )}
            {data.entity.type === 'photoGroup' && (
              <PhotoGroupNode
                childCount={childCount}
                enabledEditNodeId={enabledEditNodeId}
                group={data.entity}
                setEnabledEditNodeId={setEnabledEditNodeId}
                setLayerSettingsId={setLayerSettingsId}
                setLinkLayerId={setLinkLayerId}
                settingsTargetId={layerSettingsId}
              />
            )}
            {(data.entity.type === 'panorama' || data.entity.type === 'photo2D') && (
              <PhotoNode photo={data.entity} selected={selected} />
            )}
            {data.entity.type === 'create' && (
              <CreateNode
                cancel={() => {
                  setEnableCreateAnnotation(false);
                  setEnableCreateLayer(false);
                }}
                mode={data.entity.mode}
              />
            )}
          </div>
        </div>
      );
    },
    [
      annotationDraft?.annotationId,
      targetPhoto,
      enabled2D,
      layerSettingsId,
      enabledSelectMode,
      selectedSceneEntities,
      enabledEditNodeId,
      openConfirmationModal,
      layerClippingId,
    ],
  );

  const RenderLayerSettingMenu = useCallback(
    (layer: LayerGroup | Layer | AnnotationGroup | PhotoGroup) => {
      if (layer.type === 'layer') {
        switch (layer.formatType) {
          case LAYER_FORMAT_TYPE.IFC:
            return (
              <IFCSettingsMenu
                layer={layer}
                selectedIfcElement={selectedIfcElement}
                setSelectedIfcElement={setSelectedIfcElement}
              />
            );
          case LAYER_FORMAT_TYPE.POINT_CLOUD:
            return <PointCloudSettingsMenu layer={layer} />;
          case LAYER_FORMAT_TYPE.MESH_3D:
            return <TilesetSettingsMenu layer={layer} />;
          case LAYER_FORMAT_TYPE.DXF:
          case LAYER_FORMAT_TYPE.OBJ:
          case LAYER_FORMAT_TYPE.BIM_CAD_MODEL:
            return <Mesh3DSettingsMenu layer={layer} />;
        }
      } else {
        return <GroupSettingsMenu layer={layer} />;
      }
    },
    [selectedIfcElement],
  );

  const allSceneEntities = useMemo(
    () => [...layerGroups, ...layers, ...annotationGroups, ...photo2DGroups, ...panoramaGroups],
    [annotationGroups, layerGroups, layers, panoramaGroups, photo2DGroups],
  );
  const layerSettings = useMemo(
    () => allSceneEntities.find(entity => entity.id === layerSettingsId),
    [allSceneEntities, layerSettingsId],
  );
  const layerClipping = useMemo(
    () => allSceneEntities.find(entity => entity.id === layerClippingId),
    [allSceneEntities, layerClippingId],
  );
  const linkLayer = useMemo(
    () => allSceneEntities.find(entity => entity.id === linkLayerId),
    [allSceneEntities, linkLayerId],
  );

  const selectedAnnotations = useMemo(() => {
    return selectedSceneEntities
      .map(entity => {
        if (entity.entity.type === 'annotation') {
          return entity.entity;
        }
      })
      .filter(el => el) as Annotation[];
  }, [selectedSceneEntities]);

  // Check permissions of selected annotations
  const sceneEntityIdsWithoutPermissions = useMemo(() => {
    const sceneEntityIds: SceneEntity['entity']['id'][] = [];
    const sceneEntityIdsWithoutPermissions = [];
    for (const annotation of selectedAnnotations) {
      sceneEntityIds.push(annotation.group.sceneEntityId);
    }

    for (const sceneEntityId of sceneEntityIds) {
      const permission = getSceneEntityPermission(sceneEntityId);
      const canEdit = canPolicyActionEdit(permission);

      if (!canEdit) {
        sceneEntityIdsWithoutPermissions.push(sceneEntityId);
      }
    }

    return sceneEntityIdsWithoutPermissions;
  }, [getSceneEntityPermission, selectedAnnotations]);

  // Fetch project information
  useEffect(() => {
    fetchProject().then(project => {
      if (project) {
        setProjectName(project.name);
        document.title = project.name;
      }
    });
  }, [fetchProject]);

  useEffect(() => {
    return () => {
      document.title = 'Explore';
    };
  }, []);

  // Clear selected scene entities when selec mode is disabled
  useEffect(() => {
    if (!enabledSelectMode) {
      setSelectedSceneEntities([]);
    }
  }, [enabledSelectMode]);

  // Debounced search handler
  const handleSearch = useMemo(() => {
    return debounce(setSearchKey, 300);
  }, [setSearchKey]);

  const onSelectedSceneEntities = (el: SceneEntity[]) => {
    if (!enabledSelectMode) return;

    for (const sceneEntity of el) {
      if (sceneEntity.entity.type === 'annotation') {
        const selectedSet = new Set(selectedSceneEntities);

        selectedSet.has(sceneEntity)
          ? selectedSet.delete(sceneEntity)
          : el.forEach(e => selectedSet.add(e));
        setSelectedSceneEntities([...selectedSet]);
      }
      if (sceneEntity.entity.type === 'annotationGroup') {
        const sceneEntities = new Set(sceneEntity.children);
        const selectedSet = new Set(selectedSceneEntities);

        const hasCommonElement = sceneEntity.children.some(entity => selectedSet.has(entity));
        if (hasCommonElement) {
          sceneEntities.forEach(entity => selectedSet.delete(entity));
        } else {
          sceneEntities.forEach(entity => selectedSet.add(entity));
        }

        setSelectedSceneEntities([...selectedSet]);
      }
    }
  };

  const handleMoveAnnotations = () => {
    if (!sceneEntityIdsWithoutPermissions.length) {
      setEnabledAnnotationGroupsMenu(true);
    } else {
      setPermissionMenuType('move');
      setEnablePermissionMenu(true);
    }
  };

  const getEntityName = (entity: SceneEntity['entity']) => {
    if ('name' in entity) {
      return entity.name;
    }
    return '';
  };

  const handleDeleteAnnotations = () => {
    setOpenConfirmationModal({
      isOpen: true,
      title: `Delete ${selectedSceneEntities.length > 1 ? 'Annotations' : 'Annotation'}`,
      description: `This action cannot be undone. Are you sure you want to delete ${
        selectedSceneEntities.length > 1
          ? 'these annotations'
          : getEntityName(selectedSceneEntities[0].entity)
      }?`,
      actionButton: 'Confirm',
      actionFunction: async () => {
        if (!sceneEntityIdsWithoutPermissions.length) {
          deleteAnnotations();
        } else {
          setPermissionMenuType('delete');
          setEnablePermissionMenu(true);
        }
      },

      cancelFunction: async () => {
        toast({
          message: `Deleting ${selectedAnnotations.length} annotation${
            selectedAnnotations.length !== 1 ? 's' : ''
          } was canceled`,
          lifespan: 5000,
          clickToDismiss: true,
        });
      },
    });
  };

  const handleSelectMode = (value: boolean) => {
    useViewer.setState({ enabledSelectMode: value });
    hideRightSideBar();
    dropdownRef.current?.focus();
    dropdownRef.current?.blur();
  };

  const handleCancelAnnotationGroupsMenu = () => {
    setEnabledAnnotationGroupsMenu(false);
    toast({
      message: `Moving ${selectedAnnotations.length} annotation${
        selectedAnnotations.length !== 1 ? 's' : ''
      } was canceled`,
      lifespan: 5000,
      clickToDismiss: true,
    });
  };

  const handleProceedAnnotationGroupsMenu = () => {
    setEnabledAnnotationGroupsMenu(false);
    setSelectedSceneEntities([]);
  };

  const deleteAnnotations = async () => {
    setOpenConfirmationModal(state => {
      return { ...state, isOpen: false };
    });

    // Remove annotations which does not have permissions
    const filteredAnnotationIds = [];
    for (const selectedSceneEntity of selectedSceneEntities) {
      if (
        selectedSceneEntity.entity.type === 'annotation' &&
        !sceneEntityIdsWithoutPermissions.includes(selectedSceneEntity.entity.group.sceneEntityId)
      ) {
        filteredAnnotationIds.push(selectedSceneEntity.entity.id);
      }
    }

    const chunkSize = 500;
    const annotationChunks = chunk(filteredAnnotationIds, chunkSize);
    await Promise.all(
      annotationChunks.map(async (annotationIds: Annotation['id'][]) => {
        await deleteAnnotation.mutateAsync({
          annotationIds,
        });
      }),
    );
    setSelectedSceneEntities([]);
  };

  const handleProceedAnnotationPermissionMenu = () => {
    if (permissionMenuType === 'delete') {
      deleteAnnotations();
    } else {
      setEnabledAnnotationGroupsMenu(true);
    }
    setEnablePermissionMenu(false);
  };

  const handleCancelAnnotationPermissionMenu = () => {
    if (permissionMenuType === 'delete') {
      toast({
        message: `Deleting ${selectedAnnotations.length} annotation${
          selectedAnnotations.length !== 1 ? 's' : ''
        } was canceled`,
        lifespan: 5000,
        clickToDismiss: true,
      });
    } else {
      toast({
        message: `Moving ${selectedAnnotations.length} annotation${
          selectedAnnotations.length !== 1 ? 's' : ''
        } was canceled`,
        lifespan: 5000,
        clickToDismiss: true,
      });
    }
    setOpenConfirmationModal(state => {
      return { ...state, isOpen: false };
    });
    setEnablePermissionMenu(false);
  };

  return (
    <div className={cn('flex flex-col px-1 gap-3 h-full w-full relative')}>
      <div
        className={cn(
          'border-neutral-300',
          'border-1',
          'border-b-solid',
          'pb-3',
          'flex',
          'flex-row',
          'items-center',
          'justify-between',
        )}
      >
        <p className={cn('typo-heading-4 color-neutral-800', 'text')}>{projectName}</p>

        <SkandDropdown
          className="h-[36px] min-w-[121px] whitespace-nowrap"
          label={enabledSelectMode ? 'Select mode' : 'Default mode'}
          ref={dropdownRef}
          width="full"
        >
          <DropdownItem onClick={() => handleSelectMode(false)}>Default mode </DropdownItem>
          <DropdownItem onClick={() => handleSelectMode(true)}>Select mode</DropdownItem>
        </SkandDropdown>
      </div>

      <DeleteConfirmationModal
        buttonContainerClassName="gap-3"
        className="h-[156px] min-w-[484px] px-6 pb-6 pt-7"
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />

      <div className={cn('flex gap-2 relative')}>
        <div className="w-full">
          <Input
            data-testid="input"
            label="Search"
            onChange={handleSearch}
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>
        <Button className="px-5" onClick={() => setEnabledFilterMenu(!enabledFilterMenu)} size="s">
          Filter
        </Button>

        {enabledFilterMenu && (
          <FilterMenu
            closeMenu={() => setEnabledFilterMenu(false)}
            layerFilterKeys={filteredLayerKeys}
            setLayerFilterKeys={setFilteredLayerKeys}
            setTemplateFilterKeys={setFilteredTemplateKeys}
            templateFilterKeys={filteredTemplateKeys}
          />
        )}
      </div>

      <DropdownMenu.Root onOpenChange={setIsAddDropdownOpen} open={isAddDropdownOpen}>
        <DropdownMenu.Trigger asChild>
          <Button
            active={isAddDropdownOpen}
            className={cn(
              'relative cursor-pointer',
              (enableCreateAnnotation || enableCreateLayer || !canEdit) && 'pointer-events-none',
            )}
            disabled={enableCreateAnnotation || enableCreateLayer || !canEdit}
            filled
            primary
            size="s"
          >
            Add
            <div className="i-skand-dropdown absolute right-2 top-2" />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content asChild>
            <Menu className="mt-0 w-368px">
              <DropdownMenu.Item asChild>
                <MenuItem
                  className="cursor-pointer text-center outline-none"
                  onClick={() => {
                    setEnableCreateLayer(true);
                    setIsAddDropdownOpen(false);
                  }}
                >
                  New layer group
                </MenuItem>
              </DropdownMenu.Item>
              <DropdownMenu.Item asChild>
                <MenuItem
                  className="cursor-pointer text-center outline-none"
                  onClick={() => {
                    setEnableCreateAnnotation(true);
                    setIsAddDropdownOpen(false);
                  }}
                >
                  New annotation group
                </MenuItem>
              </DropdownMenu.Item>
            </Menu>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      {isLoading ? (
        <BlockLoading />
      ) : !allSceneEntities.length && !enableCreateAnnotation && !enableCreateLayer ? (
        <EmptyResourceMessage
          emptyResourceContent={{
            actionButton: () => {
              location.href = `${DATASET_ROOT}/projects/${projectId}`;
            },
            emptyDesc: 'Create layers via the Project page.',
            emptyTitle: 'No layers available',
            buttonLabel: 'Go to Project page',
          }}
        />
      ) : (
        <div
          className="flex-1 overflow-hidden"
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.LAYER_TREE}
        >
          <SceneEntityTree
            createAnnotationNode={enableCreateAnnotation}
            createLayerNode={enableCreateLayer}
            layerFilterKeys={filteredLayerKeys}
            onSelected={onSelectedSceneEntities}
            refreshScene
            searchKey={searchKey}
            selected={selectedSceneEntities}
            templateFilterKeys={filteredTemplateKeys}
          >
            {TreeNode}
          </SceneEntityTree>
        </div>
      )}

      {layerSettings && (
        <DraggableMenu
          closeMenu={() => {
            setLayerSettingsId(null), setSelectedIfcElement(null);
          }}
        >
          {RenderLayerSettingMenu(layerSettings)}
        </DraggableMenu>
      )}
      {layerSettings && selectedIfcElement && (
        <DraggableMenu closeMenu={() => setSelectedIfcElement(null)} x={675}>
          <IFCPropertiesMenu node={selectedIfcElement} />
        </DraggableMenu>
      )}
      {layerClipping && (
        <ClippingMenu closeMenu={() => setLayerClippingId(null)} layer={layerClipping as Layer} />
      )}
      {linkLayerId && (
        <LinkLayerMenu
          closeMenu={() => setLinkLayerId(null)}
          target={linkLayer as LayerGroup | Layer | AnnotationGroup}
        />
      )}
      {annotationUploadGroupId && (
        <AnnotationUploadMenu
          closeMenu={() => setAnnotationUploadGroupId(null)}
          groupId={annotationUploadGroupId}
        />
      )}

      <SelectContextMenu
        disable={
          enabledAnnotationGroupsMenu || enablePermissionMenu || openConfirmationModal.isOpen
        }
        onClear={() => setSelectedSceneEntities([])}
        onDelete={handleDeleteAnnotations}
        onMove={handleMoveAnnotations}
        sceneEntities={selectedSceneEntities}
        visible={enabledSelectMode}
      />

      {enabledAnnotationGroupsMenu && (
        <AnnotationGroupsMenu
          annotations={selectedAnnotations}
          cancelAction={handleCancelAnnotationGroupsMenu}
          proceedAction={handleProceedAnnotationGroupsMenu}
          sceneEntityIdsWithoutPermissions={sceneEntityIdsWithoutPermissions}
        />
      )}

      {enablePermissionMenu && (
        <AnnotationPermissionMenu
          annotations={selectedAnnotations}
          cancelAction={handleCancelAnnotationPermissionMenu}
          proceedAction={handleProceedAnnotationPermissionMenu}
          sceneEntityIdsWithoutPermissions={sceneEntityIdsWithoutPermissions}
          type={permissionMenuType}
        />
      )}
    </div>
  );
};
