import {
  NODES_TABLE_DEFAULT_PAGE_INDEX,
  NODES_TABLE_DEFAULT_PAGE_SIZE,
  SearchOption,
} from '@/constants/node';
import { SubjectType } from '@/constants/policy';
import {
  FileNode,
  FolderNode,
  LinkNode,
  PermissionPolicy,
  UserGroup,
  UserV2,
} from '@/graphql/codegen/graphql';
import { RowSelectionState, SortingState, Updater } from '@tanstack/react-table';
import { create } from 'zustand';

export type SystemNode = FileNode | FolderNode | LinkNode;

export type UserWithType = UserV2 & { type: SubjectType };

export type UserGroupWithType = UserGroup & { type: SubjectType };

export type UserOrGroup = UserWithType | UserGroupWithType;

export type SubjectWithPolicy = UserOrGroup & {
  policy: PermissionPolicy;
  isUpdated?: boolean;
  isNew?: boolean;
};

export interface FoldersPageState {
  isOpenEditAccessDialog: boolean;
  editingSystemNodeId: string | null;
  editingSubjectsWithPermissionPolicy: SubjectWithPolicy[];
  isOpenAddUserOrGroupDialog: boolean;
  usersAndGroups: UserOrGroup[];
  isOpenMoveToDialog: boolean;
  editingMultipleSystemNodeId: string[];
  parentNodeId: string | null;
  searchKeyword: string;
  searchOption: SearchOption;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  sorting: SortingState;
  rowSelection: RowSelectionState;
  serverConfirmedNameChanges: Record<string, string>;
  deletedSystemNodeIds: Record<string, boolean>;
}

export const useDataManagementPageStore = create<FoldersPageState>(() => ({
  isOpenEditAccessDialog: false,
  editingSystemNodeId: null,
  editingSubjectsWithPermissionPolicy: [],
  isOpenAddUserOrGroupDialog: false,
  usersAndGroups: [],
  isOpenMoveToDialog: false,
  editingMultipleSystemNodeId: [],
  parentNodeId: null,
  searchKeyword: '',
  searchOption: SearchOption.LOCAL,
  pagination: {
    pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX,
    pageSize: NODES_TABLE_DEFAULT_PAGE_SIZE,
  },
  sorting: [],
  rowSelection: {},
  serverConfirmedNameChanges: {},
  deletedSystemNodeIds: {},
}));

export const addToServerConfirmedNameChanges = (systemNodeId: string, newName: string) =>
  useDataManagementPageStore.setState(state => {
    const serverConfirmedNameChanges = {
      ...state.serverConfirmedNameChanges,
      [systemNodeId]: newName,
    };

    return {
      serverConfirmedNameChanges,
    };
  });

export const addToDeletedSystemNodeIds = (systemNodeId: string) =>
  useDataManagementPageStore.setState(state => {
    const deletedSystemNodeIds = {
      ...state.deletedSystemNodeIds,
      [systemNodeId]: true,
    };

    return {
      deletedSystemNodeIds,
    };
  });

export const clearDeletedSystemNodeIds = () =>
  useDataManagementPageStore.setState(() => ({
    deletedSystemNodeIds: {},
  }));

export const clearServerConfirmedNameChanges = () =>
  useDataManagementPageStore.setState(() => ({
    serverConfirmedNameChanges: {},
  }));

export const clearRowSelection = () =>
  useDataManagementPageStore.setState(() => ({
    rowSelection: {},
  }));

export const setRowSelection = (rowSelection: RowSelectionState | Updater<RowSelectionState>) =>
  useDataManagementPageStore.setState(state => {
    const newRowSelection =
      typeof rowSelection === 'function' ? rowSelection(state.rowSelection) : rowSelection;

    console.log('newRowSelection', newRowSelection);

    return {
      rowSelection: newRowSelection,
    };
  });

export const setSorting = (sorting: SortingState | Updater<SortingState>) => {
  useDataManagementPageStore.setState(state => {
    const newSorting = typeof sorting === 'function' ? sorting(state.sorting) : sorting;
    return { sorting: newSorting };
  });
};

export const setPagination = (
  update: (prevPagination: { pageIndex: number; pageSize: number }) => {
    pageIndex: number;
    pageSize: number;
  },
) =>
  useDataManagementPageStore.setState(state => ({
    pagination: update(state.pagination),
  }));

export const handlePaginationChange = (
  newPaginationOrUpdater:
    | { pageIndex: number; pageSize: number }
    | ((arg0: { pageIndex: number; pageSize: number }) => { pageIndex: number; pageSize: number }),
) => {
  setPagination(prev => {
    if (typeof newPaginationOrUpdater === 'function') {
      return newPaginationOrUpdater(prev);
    }
    return { ...prev, ...newPaginationOrUpdater };
  });
};

export const setParentNodeId = (parentNodeId: string | null) =>
  useDataManagementPageStore.setState(() => ({
    parentNodeId,
  }));

export const setSearchKeyword = (searchKeyword: string) =>
  useDataManagementPageStore.setState(() => ({
    searchKeyword,
  }));

export const setSearchOption = (searchOption: SearchOption) =>
  useDataManagementPageStore.setState(() => ({
    searchOption,
  }));

export const setEditingMultiplSystemNodeId = (editingMultipleSystemNodeId: string[]) =>
  useDataManagementPageStore.setState(() => ({
    editingMultipleSystemNodeId,
  }));

export const setEditingSystemNodeId = (editingSystemNodeId: string | null) =>
  useDataManagementPageStore.setState(() => ({
    editingSystemNodeId,
  }));

export const setIsOpenEditAccessDialog = (isOpenEditAccessDialog: boolean) =>
  useDataManagementPageStore.setState(() => ({
    isOpenEditAccessDialog,
  }));

export const setEditingSubjectsWithPermissionPolicy = (
  editingSubjectsWithPermissionPolicy: SubjectWithPolicy[],
) =>
  useDataManagementPageStore.setState(() => ({
    editingSubjectsWithPermissionPolicy,
  }));

export const removeEditingSubjectsWithPermissionPolicyById = (subjectId: string) =>
  useDataManagementPageStore.setState(state => {
    const editingSubjectsWithPermissionPolicy = [...state.editingSubjectsWithPermissionPolicy];

    const removeIndex = editingSubjectsWithPermissionPolicy.findIndex(
      subject => subject.id === subjectId,
    );
    if (removeIndex !== -1) {
      editingSubjectsWithPermissionPolicy.splice(removeIndex, 1);
    }

    return {
      editingSubjectsWithPermissionPolicy,
    };
  });

export const setIsOpenAddUserOrGroupDialog = (isOpenAddUserOrGroupDialog: boolean) =>
  useDataManagementPageStore.setState(() => ({
    isOpenAddUserOrGroupDialog,
  }));

export const setUsersAndGroups = (usersAndGroups: UserOrGroup[]) =>
  useDataManagementPageStore.setState(() => ({
    usersAndGroups,
  }));

export const setIsOpenMoveToDialog = (isOpenMoveToDialog: boolean) =>
  useDataManagementPageStore.setState(() => ({
    isOpenMoveToDialog,
  }));
