import logger from '@/utils/logger';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../client';
import { gql } from '../codegen';
import { ListFilesByFileIdsForDownloadQueryVariables } from '../codegen/graphql';
import { request } from '../request';

const document = gql(`
  query ListFilesByFileIdsForDownload($fileIds: [ID!]!) {
    filesByIds(fileIds: $fileIds) {
      ... on AssetFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on ImageFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on ObjFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on Ortho2dFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on Tileset3dFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
    }
  }
`);

const operationName = 'ListFilesByFileIdsForDownload';

const requestFn = async (
  variables: ListFilesByFileIdsForDownloadQueryVariables,
  signal?: AbortSignal,
) => {
  logger.debug(operationName, variables);
  const response = await request({ document, variables, signal });
  logger.debug(operationName, 'succeeded');
  return response;
};

const getQueryKey = (variables: ListFilesByFileIdsForDownloadQueryVariables) => [
  operationName,
  variables,
];

export const ListFilesByFileIdsForDownload = {
  getQueryKey,

  request: async (variables: ListFilesByFileIdsForDownloadQueryVariables, signal?: AbortSignal) =>
    queryClient.fetchQuery({
      queryFn: () => requestFn(variables, signal),
      queryKey: getQueryKey(variables),
    }),

  useQuery: (variables: ListFilesByFileIdsForDownloadQueryVariables) => {
    const query = useQuery({
      queryFn: () => requestFn(variables),
      queryKey: getQueryKey(variables),
    });
    return query;
  },
};
