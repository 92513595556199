import { Atom, atom } from 'jotai';
import { Progress } from './Progress';
import { calcAverageSpeed } from './calcAverageSpeed';

export class GroupProgress {
  private _progresses: Progress[];
  constructor(progresses: Progress[]) {
    this._progresses = progresses;
    this.itemsTotalAtom = atom(progresses.length);
  }

  readonly bytesLoadedAtom = atom(get => {
    const atoms = this._progresses.map(progress => get(progress.bytesLoadedAtom));
    return atoms.reduce((prev, curr) => prev + curr, 0);
  });

  readonly bytesTotalAtom = atom(get => {
    const atoms = this._progresses.map(progress => get(progress.bytesTotalAtom));
    return atoms.reduce((prev, curr) => prev + curr, 0);
  });

  readonly loadFinishedAtAtom = atom(get => {
    let lastFinishedAt: null | Date = null;
    const loadFinishedAts = this._progresses.map(progress => get(progress.loadFinishedAtAtom));

    for (const loadFinishedAt of loadFinishedAts) {
      if (loadFinishedAt === null) return null;
      if (lastFinishedAt === null) lastFinishedAt = loadFinishedAt;
      else if (loadFinishedAt > lastFinishedAt) lastFinishedAt = loadFinishedAt;
    }

    return lastFinishedAt;
  });
  readonly loadStartedAtAtom = atom(get => {
    let firstStartedAt: null | Date = null;
    for (const loadStartedAt of this._progresses.map(progress => get(progress.loadStartedAtAtom))) {
      if (loadStartedAt !== null) {
        if (firstStartedAt === null || loadStartedAt < firstStartedAt) {
          firstStartedAt = loadStartedAt;
        }
      }
    }
    return firstStartedAt;
  });

  readonly averageSpeedAtom = atom(get => {
    return calcAverageSpeed(
      get(this.bytesLoadedAtom),
      get(this.loadStartedAtAtom),
      get(this.loadFinishedAtAtom),
    );
  });
  readonly hasFinishedAtom = atom(get => get(this.loadFinishedAtAtom) !== null);
  readonly hasStartedAtom = atom(get => get(this.loadStartedAtAtom) !== null);
  readonly proportionAtom = atom(get => {
    return get(this.bytesTotalAtom) === 0
      ? 0
      : get(this.bytesLoadedAtom) / get(this.bytesTotalAtom);
  });
  readonly itemsLoadedAtom = atom(get => {
    const atoms = this._progresses.map(progress => get(progress.hasFinishedAtom));
    return atoms.reduce((prev, curr) => (curr ? prev + 1 : prev), 0);
  });
  readonly itemsTotalAtom: Atom<number>;
}
