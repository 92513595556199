import { DOWNLOAD_TASK_HAS_BEEN_ABORTED } from '@/domains/download/DownloadTaskManager';

export class AbortionError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'AbortionError';
  }
}

export const isAbortionError = (error: unknown) => {
  if (error === DOWNLOAD_TASK_HAS_BEEN_ABORTED) {
    return true;
  }

  if (error instanceof AbortionError) {
    return true;
  }

  if (error instanceof DOMException && error.name === 'AbortError') {
    return true;
  }

  return false;
};
