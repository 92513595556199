import { gql } from './codegen';

export const LIST_SYSTEM_NODES_BY_PARENT_NODE_ID = gql(`
query ListSystemNodesByParentNodeId($pageSize: Int!, $pageIndex: Int!, $parentNodeId: ID, $searchTerm: String, $nodeKinds: [SystemNodeKindInput]) {
  listSystemNodesByParentNodeId(pageSize: $pageSize, pageIndex: $pageIndex, parentNodeId: $parentNodeId, searchTerm: $searchTerm, nodeKinds: $nodeKinds){
    totalNumberOfPages
    totalNumberOfFiles
      data {
        ... on FileNode {
          id
          kind
          parentNodeId
          name
          isDeleted
          createdAt
          createdUser {
            id
            displayName
            firstName
            lastName
          }
          lastDownloadedAt
          lastDownloadedUser {
            id
            displayName
            firstName
            lastName
          }
          fileId
        }
        ... on FolderNode {
          id
          kind
          parentNodeId
          name
          isDeleted
          createdAt
          createdUser {
            id
            displayName
            firstName
            lastName
          }
          lastDownloadedAt
          lastDownloadedUser {
            id
            displayName
            firstName
            lastName
          }
        }
        ... on LinkNode {
          id
          kind
          parentNodeId
          name
          isDeleted
          createdAt
          sourceNodeId
          createdUser {
            id
            displayName
            firstName
            lastName
          }
          lastDownloadedAt
          lastDownloadedUser {
            id
            displayName
            firstName
            lastName
          }
        }
      }
    }
  }
`);

export const SEARCH_SYSTEM_NODES_RECURSIVE = gql(`
query SearchSystemNodesRecursive($pageSize: Int!, $pageIndex: Int!, $parentNodeId: ID, $searchTerm: String, $nodeKinds: [SystemNodeKindInput]) {
  searchSystemNodesRecursive(pageSize: $pageSize, pageIndex: $pageIndex, parentNodeId: $parentNodeId, searchTerm: $searchTerm, nodeKinds: $nodeKinds){
      totalNumberOfPages
      totalNumberOfFiles
      data {
        ... on FileNode {
            id
            kind
            parentNodeId
            name
            isDeleted
            createdAt
            createdUser {
              id
              displayName
              firstName
              lastName
            }
            lastDownloadedAt
            lastDownloadedUser {
              id
              displayName
              firstName
              lastName
            }
            fileId
        }
        ... on FolderNode {
            id
            kind
            parentNodeId
            name
            isDeleted
            createdAt
            createdUser {
              id
              displayName
              firstName
              lastName
            }
            lastDownloadedAt
            lastDownloadedUser {
              id
              displayName
              firstName
              lastName
            }
        }
      }
    }
  }
`);

export const PARENT_NODES_CHAIN_BY_PARENT_NODE_ID = gql(`
  query ParentNodesChainByParentNodeId($parentNodeId: ID) {
    parentNodesChainByParentNodeId(parentNodeId: $parentNodeId) {
      id
      kind
      accountId
      parentNodeId
      name
      createdAt
    }
  }
`);

export const FIND_SYSTEM_NODE_BY_ID = gql(`
  query Node($nodeId: ID!) {
    node(nodeId: $nodeId) {
      ... on FileNode {
        id
        kind
        accountId
        parentNodeId
        name
      }
      ... on FolderNode {
        id
        kind
        accountId
        parentNodeId
        name
      }
      ... on LinkNode {
        id
        kind
        accountId
        parentNodeId
        name
      }
    }
  }
`);

export const FIND_SYSTEM_FILE_NODE_BY_FILE_ID = gql(`
  query FindSystemFileNodeByFileId($fileId: ID!) {
    findSystemFileNodeByFileId(fileId: $fileId) {
      ... on FileNode {
        file {
          ... on AssetFile {
            signedGetObjectDownloadUrl
            storage {
              size
            }
          }
          ... on ImageFile {
            signedGetObjectDownloadUrl
            storage {
              size
            }
          }
          ... on ObjFile {
            signedGetObjectDownloadUrl
            storage {
              size
            }
          }
          ... on Ortho2dFile {
            signedGetObjectDownloadUrl
            storage {
              size
            }
          }
          ... on Tileset3dFile {
            signedGetObjectDownloadUrl
            storage {
              size
            }
          }
        }
      }
    }
  }
`);

export const LIST_USERS_BY_ACCOUNT_ID = gql(`
  query ListUsersByAccountId {
    listUsersByAccountId {
      email
      id
      accountId
      firstName
      lastName
    }
  }
`);

export const LIST_USER_GROUPS_BY_ACCOUNT_CONTEXT = gql(`
  query ListUserGroupsByAccountContext {
    listUserGroupsByAccountContext {
      createdAt
      description
      id
      accountId
      name
      updatedAt
      permissionPolicies {
        accountId
        actionType
        objectId
        objectType
        subjectId
        subjectType
      }
      userGroupToRolePolicies {
        accountId
        sourceId
        sourceType
        targetId
        targetType
      }
      userToUserGroupPolicies {
        accountId
        sourceId
        sourceType
        targetId
        targetType
      }
    }
  }
`);

export const LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT = gql(`
  query ListPermissionPoliciesWithAccountContext($subjectId: String, $subjectType: PolicySubjectTypeInput, $objectId: String, $objectType: PolicyObjectTypeInput, $actionType: PolicyActionTypeInput) {
    listPermissionPoliciesWithAccountContext(subjectId: $subjectId, subjectType: $subjectType, objectId: $objectId, objectType: $objectType, actionType: $actionType) {
      accountId
      subjectId
      subjectType
      objectId
      objectType
      actionType
      createdAt
      updatedAt
    }
  }
`);

export const LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS = gql(`
  query ListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds($subjectId: String!, $objectIds: [String], $objectTypes: [PolicyObjectTypeInput], $actionTypes: [PolicyActionTypeInput]) {
    listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds(subjectId: $subjectId, objectIds: $objectIds, objectTypes: $objectTypes, actionTypes: $actionTypes) {
      accountId
      subjectId
      subjectType
      objectId
      objectType
      actionType
      createdAt
      updatedAt
    }
  }
`);

export const FIND_SYSTEM_NODE_MOST_PERMISSIVE_ACTION_TYPE_FROM_PARENT_NODE_CHAIN_WITH_SYSTEM_NODE_ID =
  gql(`
  query FindSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeId($systemNodeId: String!) {
    findSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeId(systemNodeId: $systemNodeId)
  }`);

export const COUNT_FILES_TO_DOWNLOAD_BY_NODE_IDS = gql(`
  query CountFilesToDownloadByNodeIds($parentNodeIds: [ID]!) {
    countNumberOfNodesForParentNodes(parentNodeIds: $parentNodeIds)
  }
`);

export const GET_SIGNED_OBJECT_DOWNLOAD_URL = gql(`
  query FilesByIds($fileIds: [ID]!) {
    filesByIds(fileIds: $fileIds) {
      ... on AssetFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on ImageFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on ObjFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on Ortho2dFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on Tileset3dFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
    }
  }
`);

export const GET_SIGNED_OBJECT_PREVIEW_URL = gql(`
  query PreviewUrlsByFileIds($fileIds: [ID]!) {
    filesByIds(fileIds: $fileIds) {
      ... on AssetFile {
        signedGetObjectUrl        
      }
      ... on ImageFile {
        signedGetObjectUrl
      }
      ... on ObjFile {
        signedGetObjectUrl
      }
      ... on Ortho2dFile {
        signedGetObjectUrl
      }
      ... on Tileset3dFile {
        signedGetObjectUrl
      }
    }
  }
`);

export const LIST_PROJECTS_BY_ACCOUNT_CONTEXT = gql(`
  query ListProjectsByAccountContext {
    listProjectsByAccountContext {
      accountId
      address
      createdAt
      description
      geoid
      id
      name
      projectGroupId
    }
  }
`);

export const FOLDER_PROJECT_NODE_BY_PROJECT_ID_AND_PARENT_NODE_ID = gql(`
  query FolderProjectNodeByParentNodeId($projectId: ID!, $parentNodeId: ID) {
    folderProjectNodesOfAccountByParentNodeId(parentNodeId: $parentNodeId, siteId: $projectId) {
      createdAt
      id
      kind
      name
      parentNodeId
      siteId
    }
  }
`);

export const PARENT_PROJECT_NODES_CHAIN_BY_PARENT_NODE_ID = gql(`
  query ParentProjectNodesChainByParentNodeId($parentNodeId: ID) {
    parentProjectNodesChainByParentNodeId(parentNodeId: $parentNodeId) {
      id
      kind
      accountId
      parentNodeId
      name
      createdAt
    }
  }
`);

export const PROJECT_NODES_BY_IDS = gql(`
  query ProjectNodesByIds($projectNodeIds: [ID!]!) {
    projectNodesByIds(projectNodeIds: $projectNodeIds) {
      ... on ProjectFileNode {
        accountId
        createdAt
        id
        kind
        name
        parentNodeId
      }
      ... on ProjectFolderNode {
        accountId
        createdAt
        id
        kind
        name
        parentNodeId
      }
    }
  }
`);

export const READ_USER_BY_CONTEXT = gql(`
  query ReadUserByContext {
    readUserByContext {
      id
      accountId
      name {
        first
        last
      }
      email
      isAdmin
      isActive
    }
  }
`);

export const GET_IMAGE_FILE_BY_ID = gql(`
  query GetImageFileById($fileIds: [ID]!) {
    filesByIds(fileIds: $fileIds) {
      ... on AssetFile {
        id
        __typename
        kind
        signedGetObjectUrl
      }
      ... on ImageFile {
        id
        __typename
        kind
        signedGetObjectUrl
      }
    }
  }
`);

export const LIST_ACTION_TYPES_BY_OBJECT_TYPE = gql(`
  query ListActionTypesByObjectType($objectType: String!) {
    listActionTypesByObjectType(objectType: $objectType)
  }
`);

export const LIST_FEATURE_ENTITLEMENTS_BY_ACCOUNT_CONTEXT = gql(`
  query ListFeatureEntitlementsByAccountContext {
    listFeatureEntitlementsByAccountContext {
      enabled
      infinity
      name
      total
      type
      used
    }
  }
`);

export const ZIP_REQUEST_BY_ID = gql(`
  query GetZipRequestById($zipRequestId: ID!) {
    structuredZipRequest(structuredZipRequestId: $zipRequestId) {
      name
      signedGetObjectUrl
      size
      note
      expiresAt
    }
  }
`);

export const CHECK_SUPER_ADMIN = gql(`
  query CheckSuperAdmin {
    checkSuperAdmin
  }
`);

export const FIND_DATA_IMPORT_REQUEST_BY_DATA_IMPORT_REQUEST_ID = gql(`
  query FindDataImportRequestByDataImportRequestId($dataImportRequestId: ID!) {
    findDataImportRequestByDataImportRequestId(id: $dataImportRequestId) {
      rootFolderImportRequestId
    } 
  }
`);

export const COUNT_NUMBER_OF_SYSTEM_NODES_BY_PARENT_NODE_ID = gql(`
  query CountNumberOfSystemNodes($parentNodeId: ID, $searchTerm: String, $nodeKinds: [SystemNodeKindInput]) {
    listSystemNodesByParentNodeId(pageSize: 1, pageIndex: 0, parentNodeId: $parentNodeId, searchTerm: $searchTerm, nodeKinds: $nodeKinds){
      totalNumberOfFiles
    }
  }
`);

export const COUNT_FILE_IMPORT_REQUESTS_BY_DATA_IMPORT_REQUEST_ID = gql(`
  query CountFileImportRequestsByDataImportRequestId($dataImportRequestId: String!, $fileKind: String) {
    countFileImportRequestsByDataImportRequestId(
      dataImportRequestId: $dataImportRequestId
      fileKind: $fileKind
    )
  }
`);

export const FIND_FILE_IMPORT_REQUESTS_BY_DATA_IMPORT_REQUEST_ID = gql(`
  query FindFileImportRequestsByDataImportRequestId($dataImportRequestId: String!, $fileKind: String, $start: Int, $size: Int) {
    findFileImportRequestsByDataImportRequestId(
      dataImportRequestId: $dataImportRequestId
      fileKind: $fileKind
      start: $start
      size: $size
    ) {
      id
      kind
      fileId
    }
  }
`);

export const LIST_SYSTEM_NODES_BY_SYSTEM_NODE_IDS = gql(`
  query ListSystemNodesBySystemNodeIds(
    $systemNodeIds: [ID!]!
  ) {
    findSystemNodesByIds(systemNodeIds: $systemNodeIds) {
      ... on FileNode {
        __typename
        id
        isDeleted
        kind
        name
        parentNodeId
      }
      ... on FolderNode {
        __typename
        id
        isDeleted
        kind
        name
        parentNodeId
      }
      ... on LinkNode {
        __typename
        id
        isDeleted
        kind
        name
        parentNodeId
        sourceNodeId
      }
    }
  }
`);

export const LIST_SYSTEM_NODES_BY_PARENT_NODE_ID_FOR_DELETION = gql(`
  query ListSystemNodesByParentNodeIdForDeletion(
    $nodeKinds: [SystemNodeKindInput],
    $pageIndex: Int!,
    $pageSize: Int!,
    $parentNodeId: ID,
  ) {
    listSystemNodesByParentNodeId(
      nodeKinds: $nodeKinds,
      pageIndex: $pageIndex,
      pageSize: $pageSize,
      parentNodeId: $parentNodeId,
      searchTerm: null,
    ) {
      data {
        ... on FileNode {
          __typename
          id
          isDeleted
          kind
          name
          parentNodeId
        }
        ... on FolderNode {
          __typename
          id
          isDeleted
          kind
          name
          parentNodeId
        }
      }
      totalNumberOfPages
      totalNumberOfFiles
    }
  }
`);
