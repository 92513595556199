import { NodeGroupIcon } from '@/LegacyExplore/components/IconButton';
import { Label } from '@/LegacyExplore/components/Label';
import { MoreMenu } from '@/LegacyExplore/components/MoreMenu';
import { canPolicyActionEdit } from '@/LegacyExplore/constants/policy';
import { queryClient } from '@/LegacyExplore/graphql/client';
import {
  DeleteAnnotationGroupMutationVariables,
  RenameSceneEntityMutationVariables,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { DELETE_ANNOTATION_GROUP, RENAME_SCENE_ENTITY } from '@/LegacyExplore/graphql/mutations';
import { request } from '@/LegacyExplore/graphql/request';
import { useFetchSceneEntities } from '@/LegacyExplore/hooks/useFetchSceneEntities';
import { useFetchSceneEntityPermissions } from '@/LegacyExplore/hooks/useFetchSceneEntityPermissions';
import {
  ConfirmationModalState,
  SetOpenConfirmationModal,
} from '@/LegacyExplore/pages/ProjectPage/DeleteConfirmationModal';
import { cancelEditAnnotation, useExplore } from '@/LegacyExplore/stores/explore';
import { AnnotationGroup, useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { ANNOTATION_CSV } from '@/LegacyExplore/utils/split';
import { createGeoJsonAnnotation } from '@/LegacyExplore/utils/transformers';
import { Annotation, GeoJsonExporter, ShapeFileExporter } from '@skand/data-3d-loader';
import { Button, Menu, MenuItem, toast } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { ListItemButton } from './ListItemButton';

export interface AnnotationGroupNodeProps {
  group: AnnotationGroup;
  setLinkLayerId: (group: AnnotationGroup['id'] | null) => void;
  openConfirmationModal: ConfirmationModalState;
  setOpenConfirmationModal: SetOpenConfirmationModal;
  setEnabledEditNodeId: (id: string) => void;
  enabledEditNodeId: string;
  setAnnotationUploadGroupId: (group: AnnotationGroup['id'] | null) => void;
  childCount: number;
}

export const AnnotationGroupNode = ({
  group,
  setLinkLayerId,
  setOpenConfirmationModal,
  setEnabledEditNodeId,
  enabledEditNodeId,
  setAnnotationUploadGroupId,
  childCount,
}: AnnotationGroupNodeProps) => {
  const projectId = useExplore(state => state.projectId);
  const visibleAnnotations = useViewer(state => state.visibleAnnotations);
  const enabledSelectMode = useViewer(state => state.enabledSelectMode);

  const isVisible = group.annotations.some(annotation => visibleAnnotations.has(annotation.id));
  const { getSceneEntityPermission } = useFetchSceneEntityPermissions();
  const permission = getSceneEntityPermission(group.sceneEntityId);
  const canEdit = canPolicyActionEdit(permission);

  const treatment = useTreatments([ANNOTATION_CSV]);
  const annotationCsvFlag = treatment[ANNOTATION_CSV].treatment === 'on';

  const [editInput, setEditInput] = useState(group.name);
  const [enabledInfoToolTip, setEnabledInfoToolTip] = useState(false);

  const updateGroup = useMutation({
    mutationFn: (variables: RenameSceneEntityMutationVariables) =>
      request(RENAME_SCENE_ENTITY, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: 'Successfully updated annotation group node.',
        lifespan: 5000,
      });
    },
  });

  const deleteGroup = useMutation({
    mutationFn: (variables: DeleteAnnotationGroupMutationVariables) =>
      request(DELETE_ANNOTATION_GROUP, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: 'Successfully deleted annotation group node.',
        lifespan: 5000,
      });
    },
  });

  // Handle keypress events
  const handleInputKeypress = (key: string) => {
    if (key === 'Enter') {
      handleSubmitName();
    }
    if (key === 'Escape') {
      setEnabledEditNodeId('');
    }
  };

  // Handle submitting the edited name
  const handleSubmitName = async () => {
    if (!projectId) return;
    await updateGroup.mutateAsync({
      sceneEntityId: group.sceneEntityId,
      projectId,
      name: editInput,
    });
    setEnabledEditNodeId('');
  };

  // Handle toggling the visibility of all annotations under this group
  const handleToggle = () => {
    if (isVisible) {
      useViewer.setState(prev => {
        const visibleAnnotations = new Set(prev.visibleAnnotations);
        for (const annotation of group.annotations) {
          visibleAnnotations.delete(annotation.id);
        }
        return { visibleAnnotations };
      });
    } else {
      useViewer.setState(prev => {
        const visibleAnnotations = new Set(prev.visibleAnnotations);
        for (const annotation of group.annotations) {
          visibleAnnotations.add(annotation.id);
        }
        return { visibleAnnotations };
      });
    }
  };

  // Handle exporting 3D annotations to GeoJSON
  const handleExportGeoJSON = async () => {
    const infoToast = toast({
      type: 'info',
      message: `Exporting ${group.name} (${group.id}) to GeoJSON.`,
    });
    try {
      const geoExporter = new GeoJsonExporter();
      const annotations = await createGeoJsonAnnotation(group);
      const geojson = await geoExporter.read(annotations as Annotation[]);
      const string = JSON.stringify(geojson);
      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([string], { type: 'application/json' }));
      a.download = `${group.name} - ${group.id}.json`;
      a.click();
      toast({
        type: 'success',
        message: `Successfully exported ${group.name} (${group.id}) to GeoJSON.`,
        lifespan: 5000,
      });
    } catch (error) {
      toast({
        type: 'warn',
        message: `Failed to export ${group.name} (${group.id}) annotations to GeoJSON. ${error}`,
        lifespan: 5000,
      });
    } finally {
      infoToast.dismiss();
    }
  };

  const handleExportShapefile = async () => {
    const infoToast = toast({
      type: 'info',
      message: `Exporting ${group.name} (${group.id}) to Shapefile.`,
    });
    try {
      const shapeFileExporter = new ShapeFileExporter();
      const annotations = await createGeoJsonAnnotation(group);
      const buffer = await shapeFileExporter.read(annotations as Annotation[]);
      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([buffer], { type: 'application/x-zip' }));
      a.download = `${group.name} - ${group.id}.zip`;
      a.click();
      toast({
        type: 'success',
        message: `Successfully exported ${group.name} (${group.id}) to Shapefile.`,
        lifespan: 5000,
      });
    } catch (error) {
      toast({
        type: 'warn',
        message: `Failed to export ${group.name} (${group.id}) annotations to Shapefile. ${error}`,
        lifespan: 5000,
      });
    } finally {
      infoToast.dismiss();
    }
  };

  // Handle deleting the annotation group
  const handleDeleteAnnotationGroupNode = () => {
    setOpenConfirmationModal({
      isOpen: true,
      title: 'Delete Annotation Group',
      description: `Are you sure you want to delete the annotation group ${group.name}?`,
      actionButton: 'Delete Annotation Group',
      actionFunction: async () => {
        await deleteGroup.mutateAsync({
          annotationGroupId: group.sceneEntityId,
        });
        cancelEditAnnotation();
        setOpenConfirmationModal((state: ConfirmationModalState) => {
          return { ...state, isOpen: false };
        });
      },
    });
  };

  return (
    <>
      {enabledEditNodeId === group.id ? (
        <div className="flex flex-1">
          <input
            className={cn(
              'px-1',
              'color-neutral-800',
              'typo-text-s-em',
              'rounded',
              'border-1',
              'border-solid',
              'border-primary-400',
              'outline-none',
              'w-full',
            )}
            onChange={e => setEditInput(e.target.value)}
            onKeyDown={e => handleInputKeypress(e.key)}
            value={editInput}
          />
          <Button className="ml-1" filled onClick={handleSubmitName} primary size="xs">
            Save
          </Button>
        </div>
      ) : (
        <>
          <NodeGroupIcon className="w-3 text-3 color-neutral-600" />
          <p
            className={cn(
              'group',
              'cursor-pointer',
              'typo-text-small',
              'text-neutral-800',
              'flex-1',
              'truncate',
            )}
            onDoubleClick={() => {
              if (canEdit) {
                setEnabledEditNodeId(group.id);
                setEditInput(group.name);
              }
            }}
            title={group.name}
          >
            {group.name}
          </p>
          <div
            className={cn(
              'bg-neutral-200 py-[1px] px-1 rounded-[50px] min-w-5 h-[13px] justify-center flex items-center ',
            )}
          >
            <p className={cn('typo-text-xs-em text-neutral-500 whitespace-nowrap pt-[1px]')}>
              {childCount}
            </p>
          </div>
        </>
      )}

      <div className="w-48px" />

      <div
        className="fixed right-0 h-32px flex flex-none items-center gap-2 bg-neutral-100 pl-2"
        style={{
          boxShadow: !enabledSelectMode ? '-8px 0px 8px -2px rgba(255,255,255,1)' : 'none',
        }}
      >
        <ListItemButton
          icon={<div className={cn('i-skand-eye', isVisible ? 'i-skand-show' : 'i-skand-hide')} />}
          onClick={handleToggle}
          onMouseEnter={() => setEnabledInfoToolTip(group.annotations.length === 0)}
          onMouseLeave={() => setEnabledInfoToolTip(false)}
        />

        <MoreMenu className={cn('cursor-pointer', enabledSelectMode && 'hidden')}>
          <Menu className="z-2">
            {canPolicyActionEdit(permission) && (
              <MenuItem className="cursor-pointer" onClick={() => setLinkLayerId(group.id)}>
                Link to layer
              </MenuItem>
            )}
            <MenuItem className="cursor-pointer" onClick={handleExportGeoJSON}>
              Export 3D to GeoJSON
            </MenuItem>
            <MenuItem className="cursor-pointer" onClick={handleExportShapefile}>
              Export 3D to Shapefile
            </MenuItem>
            {annotationCsvFlag && (
              <>
                <MenuItem
                  className="cursor-pointer"
                  onClick={() => {
                    setAnnotationUploadGroupId(group.id);
                  }}
                >
                  Upload Annotations
                </MenuItem>
              </>
            )}

            {canPolicyActionEdit(permission) && (
              <MenuItem
                className="cursor-pointer"
                disabled={group.annotations.length > 0}
                onClick={handleDeleteAnnotationGroupNode}
              >
                Delete
              </MenuItem>
            )}
          </Menu>
        </MoreMenu>
      </div>
      {enabledInfoToolTip && (
        <div className="absolute right-5 z-100 mt-15">
          <Label
            css="h-36px"
            ellipsis={false}
            labelTitle="No annotations to show in this group"
            labelType="info"
            textLength={100}
          />
        </div>
      )}
    </>
  );
};
