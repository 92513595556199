import { BranchTreeNode, FileTreeNode } from '../DownloadTreeNode';
import { DownloadTask } from './DownloadTask';
import { FileDownloadTask } from './FileDownloadTask';
import { ZipDownloadTask } from './ZipDownloadTask';

export class EmptyDownloadTreeNodeError extends Error {
  readonly downloadTreeNode: BranchTreeNode;
  constructor(downloadTreeNode: BranchTreeNode) {
    super();
    this.downloadTreeNode = downloadTreeNode;
  }
}

export class DownloadTaskFactory {
  createFromTree(rootTreeNode: BranchTreeNode): DownloadTask {
    const allNodes = rootTreeNode.map(node => node);

    if (allNodes.length === 0) {
      throw new EmptyDownloadTreeNodeError(rootTreeNode);
    } else if (allNodes.length === 1 && allNodes[0] instanceof FileTreeNode) {
      return new FileDownloadTask(allNodes[0]);
    } else {
      return new ZipDownloadTask(rootTreeNode);
    }
  }
}
