import { NodeGroupIcon } from '@/components/IconButton';
import { AnnotationGroup } from '@/stores/viewer';
import { cn } from '@/utils/classname';
export interface AnnotationGroupNodeProps {
  group: AnnotationGroup;
  childCount: number;
}

export const AnnotationGroupNode = ({ group, childCount }: AnnotationGroupNodeProps) => {
  return (
    <div className={cn('flex justify-between items-center py-1 gap-3')}>
      <NodeGroupIcon className="w-5 text-3 color-neutral-600" />
      <div className={cn('flex items-center text-neutral-600 ')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{group.name}</p>
      </div>
      <div
        className={cn(
          'bg-neutral-200 py-[1px] px-1 rounded-[50px] min-w-5 h-[13px] justify-center flex items-center ',
        )}
      >
        <p className={cn('typo-text-xs-em text-neutral-500 whitespace-nowrap pt-[1px]')}>
          {childCount}
        </p>
      </div>
    </div>
  );
};
