import * as PATHS from '@/LegacyExplore/constants/paths';
import { client, queryClient } from '@/LegacyExplore/graphql/client';
import { Error404 } from '@/LegacyExplore/pages/404';
import { EmptyResourcePage } from '@/LegacyExplore/pages/EmptyResource/EmptyResourcePage';
import { ProjectPage } from '@/LegacyExplore/pages/ProjectPage/ProjectPage';
import { history as historyRef } from '@/LegacyExplore/utils/history';
import { config } from '@/LegacyExplore/utils/split';
import { ApolloProvider } from '@apollo/client';
import { ToastRoot } from '@skand/ui';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { ExplorePage } from '../pages/ExplorePage';
import { PrivateRoute } from './PrivateRoute';

export const Main = () => {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Switch>
            <Route path={`${PATHS.ERROR_404}/:errorCode?`}>
              <Error404 />
            </Route>
            <Route path={`${PATHS.EMPTY_RESOURCE}/:resourceType`}>
              <EmptyResourcePage />
            </Route>

            <PrivateRoute fallback={PATHS.ROOT}>
              <Route path={PATHS.PROJECT_ROOT}>
                <ProjectPage />
              </Route>

              <Route path={PATHS.EXPLORE_ROOT}>
                <ExplorePage />
              </Route>

              <Route path={PATHS.EXPLORE_BETA_COMPAT}>
                <ExplorePage />
              </Route>
            </PrivateRoute>
          </Switch>
        </BrowserRouter>
      </QueryClientProvider>
    </ApolloProvider>
  );
};

export const Root = () => {
  const history = useHistory();

  useEffect(() => {
    historyRef.current = history;
  }, [history]);

  return (
    <div className="h-full w-full flex">
      <SplitFactory config={config}>
        <Main />
      </SplitFactory>

      <ToastRoot className="z-1" size="l" />
    </div>
  );
};
