import { DownloadTaskManager } from '@/domains/download/DownloadTaskManager';
import { atom } from 'jotai';
import { jotaiStore } from '../jotaiStore';

class DownloadStore {
  private _store = jotaiStore;

  private _isOpenAtom = atom(false);
  private _taskManagersAtom = atom<DownloadTaskManager[]>([]);

  isOpenAtom = atom(get => {
    const isOpen = get(this._isOpenAtom);
    const taskManagers = get(this._taskManagersAtom);
    return isOpen && taskManagers.length > 0;
  });
  taskManagersAtom = atom(get => get(this._taskManagersAtom));

  createTaskManagerAndStart(systemNodeIds: string[]) {
    const currentTaskManagers = this._store.get(this._taskManagersAtom);
    const newId = DownloadTaskManager.createId(systemNodeIds);

    let taskManager = currentTaskManagers.find(t => t.id === newId);
    if (!taskManager) {
      taskManager = new DownloadTaskManager(systemNodeIds);
      this._addTaskManager(taskManager);
      taskManager.start();
    }

    this._store.set(this._isOpenAtom, true);
    return taskManager;
  }

  private _addTaskManager(taskManager: DownloadTaskManager) {
    this._store.set(this._taskManagersAtom, prev => [...prev, taskManager]);
  }

  removeTaskManager(taskManager: DownloadTaskManager) {
    this._store.set(this._taskManagersAtom, prev => prev.filter(t => t !== taskManager));
  }

  closeDialog() {
    this._store.set(this._isOpenAtom, false);
  }
}

export const downloadStore = new DownloadStore();
