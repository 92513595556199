import { toast } from '@skand/ui';
import { EmptyDownloadTreeNodeError } from './DownloadTask/DownloadTaskFactory';

export const toastError = (error: unknown) => {
  if (error instanceof EmptyDownloadTreeNodeError) {
    toast({
      type: 'info',
      message: 'There is nothing to download in your selection.',
    });
  } else {
    toast({
      type: 'warn',
      message: (
        <span>
          Something went wrong while downloading, please try again later or contact us at{' '}
          <a className="color-primary-400 typo-link-s" href="mailto:support@skand.io">
            support@skand.io
          </a>
        </span>
      ),
    });
  }
};
