import logger from '@/utils/logger';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../client';
import { gql } from '../codegen';
import { ListSystemNodesByParentNodeIdForDownloadQueryVariables } from '../codegen/graphql';
import { request } from '../request';

export const document = gql(`
  query ListSystemNodesByParentNodeIdForDownload(
    $nodeKinds: [SystemNodeKindInput!]!
    $pageIndex: Int!
    $pageSize: Int!
    $parentNodeId: ID
  ) {
    listSystemNodesByParentNodeId(
      hydrateFiles: false
      includeMigratedData: true
      nodeKinds: $nodeKinds
      pageIndex: $pageIndex
      pageSize: $pageSize
      parentNodeId: $parentNodeId
      searchTerm: null
    ) {
      data {
        ... on FileNode {
          __typename
          id
          kind
          name
          parentNodeId
          fileId
        }
        ... on FolderNode {
          __typename
          id
          kind
          name
          parentNodeId
        }
        ... on LinkNode {
          __typename
          id
          kind
          name
          parentNodeId
          sourceNodeId
        }
      }
      totalNumberOfPages
      totalNumberOfFiles
    }
  }
`);

const operationName = 'ListSystemNodesByParentNodeIdForDownload';

const requestFn = async (
  variables: ListSystemNodesByParentNodeIdForDownloadQueryVariables,
  signal?: AbortSignal,
) => {
  logger.debug(operationName, variables);
  const response = await request({ document, variables, signal });
  logger.debug(operationName, 'succeeded');
  return response;
};

const getQueryKey = (variables: ListSystemNodesByParentNodeIdForDownloadQueryVariables) => [
  operationName,
  variables,
];

export const ListSystemNodesByParentNodeIdForDownload = {
  getQueryKey,

  request: async (
    variables: ListSystemNodesByParentNodeIdForDownloadQueryVariables,
    signal?: AbortSignal,
  ) =>
    queryClient.fetchQuery({
      queryFn: () => requestFn(variables, signal),
      queryKey: getQueryKey(variables),
    }),

  useQuery: (variables: ListSystemNodesByParentNodeIdForDownloadQueryVariables) => {
    const query = useQuery({
      queryFn: () => requestFn(variables),
      queryKey: getQueryKey(variables),
    });
    return query;
  },
};
