import { NODES_TABLE_DEFAULT_PAGE_INDEX } from '@/constants/node';
import { FoldersPageTableData } from '@/pages/FoldersPages/FoldersPage/useFoldersPageColumns';
import {
  clearDeletedSystemNodeIds,
  clearServerConfirmedNameChanges,
  setPagination,
  setParentNodeId,
  setSearchKeyword,
  useDataManagementPageStore,
} from '@/stores/dataManagementStore';
import {
  clearRecentlyCreatedFolderNodes,
  useRecentlyCreatedFolderNodeStore,
} from '@/stores/recentlyCreatedFolderNodesStore';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSetPermissionsAndActionType from './useSetPermissionsAndActionType';

export const useManageSystemNodesSideEffects = ({
  displaySystemNodes,
  refetchSystemNodes,
}: {
  displaySystemNodes: FoldersPageTableData[];
  refetchSystemNodes: () => void;
}) => {
  const { recentlyCreatedFolderNodes } = useRecentlyCreatedFolderNodeStore(state => state);

  const { id } = useParams<{ id: string }>();
  const {
    pagination: { pageIndex },
    searchKeyword,
  } = useDataManagementPageStore(state => state);

  useSetPermissionsAndActionType(displaySystemNodes);

  useEffect(() => {
    setParentNodeId(id);
    setPagination(prev => ({ ...prev, pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX }));
    setSearchKeyword('');
  }, [id]);

  useEffect(() => {
    if (recentlyCreatedFolderNodes.length > 0) {
      clearRecentlyCreatedFolderNodes();
    }
    clearServerConfirmedNameChanges();
    clearDeletedSystemNodeIds();
    refetchSystemNodes();
  }, [id, pageIndex, refetchSystemNodes, searchKeyword]);
};
