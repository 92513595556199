export const calcAverageSpeed = (
  bytesLoaded: number,
  loadStartedAt: null | Date,
  loadFinishedAt: null | Date,
) => {
  if (loadStartedAt === null) return 0;

  const start = loadStartedAt.getTime();
  const end = loadFinishedAt?.getTime() ?? Date.now();
  const diff = (end - start) / 1000;
  if (diff === 0) return 0;
  return bytesLoaded / diff;
};
