import { atom } from 'jotai';
import { GroupProgress } from '../DownloadProgress/GroupProgress';
import { Progress } from '../DownloadProgress/Progress';
import { FileTreeNode } from '../DownloadTreeNode';
import { saveBlob } from './saveBlob';

export interface DownloadTask {
  progress: Progress | GroupProgress;
  name: string;
  start(signal: AbortSignal): Promise<void>;
}

export class FileDownloadTask implements DownloadTask {
  readonly progress: Progress;
  readonly treeNode: FileTreeNode;
  readonly name: string;

  readonly errorsAtom = atom<unknown[]>(get => {
    const error = get(this.treeNode.downloader.errorAtom);
    return error === null ? [] : [error];
  });

  constructor(treeNode: FileTreeNode) {
    this.name = treeNode.nodeName;
    this.progress = treeNode.downloader.progress;
    this.treeNode = treeNode;
  }

  async start(signal: AbortSignal) {
    const blob = await this.treeNode.downloader.start(signal);
    saveBlob(blob, this.name);
  }
}
