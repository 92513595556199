import { downloadStore } from '@/stores/downloadStore/downloadStore';
import { jotaiStore } from '@/stores/jotaiStore';
import { BuildSystemNodeTreeForDownloadUseCase } from '@/useCases/BuildSystemNodeTreeForDownloadUseCase';
import { isAbortionError } from '@/utils/errors/AbortionError';
import logger from '@/utils/logger';
import { atom } from 'jotai';
import { DownloadTask } from './DownloadTask/DownloadTask';
import {
  DownloadTaskFactory,
  EmptyDownloadTreeNodeError,
} from './DownloadTask/DownloadTaskFactory';
import { toastError } from './toastError';

export type DownloadTaskStage =
  | 'initial'
  | 'preparing'
  | 'downloading'
  | 'failure'
  | 'success'
  | 'aborted';

export const DOWNLOAD_TASK_HAS_BEEN_ABORTED = Symbol('download task has been aborted');

/**
 * DownloadTaskManager manages lifecycle of a download task.
 */
export class DownloadTaskManager {
  private _store = jotaiStore;
  private _abortController = new AbortController();
  readonly id: string;
  readonly systemNodeIds: string[];

  private _downloadTaskAtom = atom<null | DownloadTask>(null);
  private _errorAtom = atom<null | unknown>(null);
  private _stageAtom = atom<DownloadTaskStage>('initial');

  readonly downloadTaskAtom = atom(get => get(this._downloadTaskAtom));
  readonly errorAtom = atom(get => get(this._errorAtom));
  readonly stageAtom = atom(get => get(this._stageAtom));

  static createId(systemNodeIds: string[]) {
    const sortedSystemNodeIds = [...systemNodeIds].sort();
    return sortedSystemNodeIds.join(',');
  }

  constructor(systemNodeIds: string[]) {
    this.systemNodeIds = systemNodeIds;
    this.id = DownloadTaskManager.createId(systemNodeIds);
  }

  start = async () => {
    try {
      this._store.set(this._stageAtom, 'preparing');
      await this._prepare();
      this._store.set(this._stageAtom, 'downloading');
      await this._download();
      this._store.set(this._stageAtom, 'success');
    } catch (error) {
      this._store.set(this._errorAtom, error);
      if (isAbortionError(error)) {
        this._store.set(this._stageAtom, 'aborted');
      } else if (error instanceof EmptyDownloadTreeNodeError) {
        this._store.set(this._stageAtom, 'failure');
        logger.warn(error);
        toastError(error);

        // cancel itself
        downloadStore.removeTaskManager(this);
        this.abort();
      } else {
        this._store.set(this._stageAtom, 'failure');
        logger.warn(error);
        toastError(error);
        throw error;
      }
    }
  };

  retry = async () => {
    if (this._abortController.signal.aborted) {
      this._abortController = new AbortController();
    }

    this._store.set(this._errorAtom, null);
    this._store.set(this._stageAtom, 'initial');
    await this.start();
  };

  abort() {
    // Abortion reason will always be logged as a warning in console by browser.
    // If we set pass an error in, like `this._abortController.abort(new AbortionError())`,
    // The error will be printed repeatedly in console. So we use a symbol instead.
    // Also remember to check it in `isAbortionError`.
    this._abortController.abort(DOWNLOAD_TASK_HAS_BEEN_ABORTED);
  }

  private async _prepare() {
    // skip preparation if downloadTask is already prepared
    const currentDownloadTask = this._store.get(this._downloadTaskAtom);
    if (currentDownloadTask) return;

    const useCase = new BuildSystemNodeTreeForDownloadUseCase(this._abortController.signal);
    const rootTreeNode = await useCase.execute(this.systemNodeIds);

    const downloadTaskFactory = new DownloadTaskFactory();
    const downloadTask = downloadTaskFactory.createFromTree(rootTreeNode);
    this._store.set(this._downloadTaskAtom, downloadTask);
  }

  private async _download() {
    const downloadTask = this._store.get(this._downloadTaskAtom)!;
    await downloadTask.start(this._abortController.signal);
  }
}
