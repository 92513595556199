import { FindIcon } from '@/LegacyExplore/components/IconButton';
import { RadioButton } from '@/LegacyExplore/components/RadioButton';
import { SceneEntity, SceneEntityTree } from '@/LegacyExplore/components/SceneEntityTree';
import { queryClient } from '@/LegacyExplore/graphql/client';
import { MoveAnnotationsToGroupMutationVariables } from '@/LegacyExplore/graphql/codegen/graphql';
import { MOVE_ANNOTATIONS_TO_GROUP } from '@/LegacyExplore/graphql/mutations';
import { request } from '@/LegacyExplore/graphql/request';
import { useRefetchAnnotations } from '@/LegacyExplore/hooks/useFetchAnnotations';
import { useFetchSceneEntities } from '@/LegacyExplore/hooks/useFetchSceneEntities';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { Annotation, Layer, LayerGroup, useViewer } from '@/LegacyExplore/stores/viewer';
import { chunk } from '@/LegacyExplore/utils/chunk';
import { cn } from '@/LegacyExplore/utils/classname';
import { debounce } from '@/LegacyExplore/utils/debounce';
import { Matrix4 } from '@skand/math';
import { Button, Input, Modal, toast, TreeNodeProps } from '@skand/ui';
import { Sketch } from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AnnotationGroupNode } from './AnnotationGroupNode';
import { AnnotationNode } from './AnnotationNode';
import { CreateAnnotationGroup } from './CreateAnnotationGroup';
import { LayerGroupNode } from './LayerGroupNode';
import { LayerNode } from './LayerNode';
interface AnnotationGroupsMenuProps {
  cancelAction: () => void;
  proceedAction: () => void;
  annotations: Annotation[];
  sceneEntityIdsWithoutPermissions: SceneEntity['entity']['id'][];
}

export const AnnotationGroupsMenu = ({
  annotations,
  cancelAction,
  proceedAction,
  sceneEntityIdsWithoutPermissions,
}: AnnotationGroupsMenuProps) => {
  const projectId = useExplore(state => state.projectId);
  const viewerAnnotationGroups = useViewer(state => state.annotationGroups);

  const [selectedAnnotationGroup, setSelectedAnnotationGroup] = useState<
    SceneEntity['entity']['id'] | null
  >(null);
  const [filteredAnnotations, setFilteredAnnotations] = useState<Annotation[]>([]);
  const { refetch: refetchAnnotations } = useRefetchAnnotations();

  const [searchKey, setSearchKey] = useState('');
  const [targetLayer, setTargetLayer] = useState<Layer | LayerGroup | null>(null);
  const [enabledCreateAnnotation, setEnabledCreateAnnotation] = useState(false);

  useEffect(() => {
    if (!selectedAnnotationGroup) return;
    const isSameGroup = annotations.some(el => el.group.id === selectedAnnotationGroup);
    if (isSameGroup) {
      toast({
        message: 'You can’t move the annotations into the same group.',
        lifespan: 3000,
        clickToDismiss: true,
        type: 'info',
      });
    }
  }, [annotations, selectedAnnotationGroup]);

  // Remove annotations which does not have permissions
  useEffect(() => {
    const annotationsWithPermissions = annotations.filter(
      el => !sceneEntityIdsWithoutPermissions.includes(el.group.sceneEntityId),
    );

    setFilteredAnnotations(annotationsWithPermissions);
  }, [annotations, sceneEntityIdsWithoutPermissions]);

  // Debounced search handler
  const handleSearch = useMemo(() => {
    return debounce(setSearchKey, 300);
  }, [setSearchKey]);

  const handleAnnotationGroupClick = (el: SceneEntity) => {
    setSelectedAnnotationGroup(el.entity.id);
  };

  const moveAnnotation = useMutation({
    mutationFn: (variables: MoveAnnotationsToGroupMutationVariables) => {
      return request(MOVE_ANNOTATIONS_TO_GROUP, variables);
    },
    onSuccess: ({ moveAnnotationsToGroup }) => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      useViewer.setState(state => {
        for (const group of state.annotationGroups) {
          group.loadState = 'pending';
        }
        return { annotationGroups: [...state.annotationGroups] };
      });

      if (moveAnnotationsToGroup) {
        toast({
          message: `Successfully moved ${moveAnnotationsToGroup.length} annotation${
            moveAnnotationsToGroup.length > 1 ? 's' : ''
          }`,
          lifespan: 5000,
          clickToDismiss: true,
          type: 'success',
        });
      }
    },
  });

  const handleMoveToGroup = async (annotations: Annotation[]) => {
    if (!selectedAnnotationGroup) return;
    const annotationIdsWithPositionAndRotation = [];
    const annotationGroupIds = new Set<Annotation['group']['id']>();

    for (const annotation of annotations) {
      const currGroup = viewerAnnotationGroups.find(group => group.id === annotation.group.id);
      const nextGroup = viewerAnnotationGroups.find(group => group.id === selectedAnnotationGroup);
      const currAnnotation = currGroup?.annotations.find(el => el.id === annotation.id);

      if (currGroup && nextGroup && currAnnotation && currAnnotation.sketch3D) {
        const sketch = currAnnotation.sketch3D.getModel() as Sketch;
        const transform = new Matrix4().compose(
          sketch.getPosition(),
          sketch.getRotation(),
          sketch.getScale(),
        );
        const inverseGroupTransform = new Matrix4()
          .compose(
            nextGroup.sceneNode.getPosition(),
            nextGroup.sceneNode.getRotation(),
            nextGroup.sceneNode.getScale(),
          )
          .invert();
        const vertices = sketch
          .getVertices()
          .map(vertex => vertex.applyMatrix4(transform).applyMatrix4(inverseGroupTransform));

        annotationIdsWithPositionAndRotation.push({
          annotationId: annotation.id,
          positions: vertices,
          rotations: vertices.map(() => ({ x: 0, y: 0, z: 0, w: 1 })),
        });
        annotationGroupIds.add(annotation.group.id);
      }
    }

    const chunkSize = 500;
    const annotationIdChunks = chunk(annotationIdsWithPositionAndRotation, chunkSize);
    await Promise.all(
      annotationIdChunks.map(async annotationIds => {
        await moveAnnotation.mutateAsync({
          annotationIdsWithPositionAndRotation: annotationIds,
          groupId: selectedAnnotationGroup,
        });
      }),
    );

    refetchAnnotations([...annotationGroupIds, selectedAnnotationGroup]);
  };

  const handleMoveToGroupClick = async () => {
    await handleMoveToGroup(filteredAnnotations);
    proceedAction();
  };

  // Define Tree node component
  const TreeNode = useCallback(
    ({ data, isLeaf, depth, setOpen, isOpen }: TreeNodeProps<SceneEntity>) => {
      const childCount = data.children.length;
      // Automatically expand selected layer group if there's target layer
      if (targetLayer && targetLayer.id === data.entity.id && !isOpen) {
        setOpen(true);
      }

      return (
        <div
          className={cn(
            'inline-flex flex-row items-center gap-2 py-1 pr-2 h-7 w-full',
            targetLayer?.id === data.entity.id && 'bg-neutral-200',
          )}
          style={{ marginLeft: `${depth * 0.5}rem` }}
        >
          <div
            className={cn(
              !isLeaf ? 'i-skand-dropdownarrow cursor-pointer' : 'h-1em',
              'min-w-1em',
              'text-neutral-400',
              'text-3',
              'mr-2',

              !isOpen && 'rotate-270',
            )}
            onClick={() => setOpen(!isOpen)}
          />
          {data.entity.type === 'annotationGroup' && (
            <RadioButton
              checked={data.entity.id === selectedAnnotationGroup}
              className="pt-[5px]"
              id="group"
              onChange={() => handleAnnotationGroupClick(data)}
              value={selectedAnnotationGroup as string}
            />
          )}
          {data.entity.type === 'layerGroup' && (
            <LayerGroupNode
              childCount={childCount}
              group={data.entity}
              setTargetLayer={setTargetLayer}
            />
          )}
          {data.entity.type === 'layer' && (
            <LayerNode
              childCount={childCount}
              layer={data.entity}
              setTargetLayer={setTargetLayer}
            />
          )}
          {data.entity.type === 'annotation' && <AnnotationNode annotation={data.entity} />}
          {data.entity.type === 'annotationGroup' && (
            <AnnotationGroupNode childCount={childCount} group={data.entity} />
          )}
          {data.entity.type === 'create' && (
            <CreateAnnotationGroup
              cancel={() => {
                setEnabledCreateAnnotation(false);
              }}
              targetLayer={targetLayer as Layer | LayerGroup}
            />
          )}
        </div>
      );
    },
    [targetLayer, selectedAnnotationGroup],
  );

  return (
    <Modal className="h-[620px] w-[692px] flex">
      <></>
      <div className="mt-2 flex flex-1 flex-col justify-between px-6 py-7">
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <p className="color-neutral-800 typo-text-m">
              Move {filteredAnnotations.length} selected annotations
            </p>
            <Button
              className={cn(
                'cursor-pointer',
                'w-[134px]',
                enabledCreateAnnotation && 'bg-primary-300',
              )}
              onClick={() => setEnabledCreateAnnotation(true)}
              primary
              size="s"
            >
              Create a new group
            </Button>
          </div>
          <p className="color-neutral-800 typo-text-s">
            Choose a different annotation group to move your selections, or create a new group.
          </p>
        </div>
        <div
          className={cn(
            'h-[444px] flex flex-col rounded-lg gap-[10px]	p-3 border-[1px] border-solid border-neutral-400',
          )}
        >
          <div className={cn('w-full')}>
            <Input
              data-testid="input"
              label="Search"
              onChange={handleSearch}
              tail={<FindIcon />}
              value={searchKey}
            />
          </div>
          {enabledCreateAnnotation && (
            <div
              className={cn(
                'w-[455px] px-3 py2 bg-info-100 border border-info-400 border-solid	rounded flex flex-row',
              )}
            >
              <div className="flex items-center gap-[14px]">
                <div className="i-skand-info text-[12px] color-info-400" />
                <div>
                  <p className="color-neutral-800 typo-text-s">
                    You can also select a layer group to add your new annotation group under it.
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className={cn('h-[360px] overflow-y-scroll')}>
            <SceneEntityTree
              createAnnotationNode={enabledCreateAnnotation}
              searchKey={searchKey}
              skippedEntities={['photoGroup', 'photo']}
              targetLayer={targetLayer as LayerGroup | Layer}
            >
              {TreeNode}
            </SceneEntityTree>
          </div>
        </div>
        <div className="w-full flex gap-2">
          <Button className="w-full cursor-pointer" onClick={cancelAction} size="s">
            Cancel
          </Button>
          <Button
            className="w-full cursor-pointer"
            disabled={!selectedAnnotationGroup}
            filled
            onClick={handleMoveToGroupClick}
            primary
            size="s"
          >
            Move
          </Button>
        </div>
      </div>
    </Modal>
  );
};
