import * as PATHS from '@/constants/paths';
import { queryClient } from '@/graphql/client';
import { DataDownloadPage } from '@/pages/DataDownloadPage';
import { DataManagementPage } from '@/pages/FoldersPages/FoldersPage';
import { jotaiStore } from '@/stores/jotaiStore';
import { config } from '@/utils/split';
import { ToastRoot } from '@skand/ui';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { DownloaderV2 } from './DownloaderV2/DownloaderV2';
import { PrivateRoute } from './PrivateRoute';

export const Root = () => {
  return (
    <Provider store={jotaiStore}>
      <QueryClientProvider client={queryClient}>
        <SplitFactory config={config}>
          <BrowserRouter>
            <Switch>
              <Route path={PATHS.DATASETS_ZIP_DOWNLOAD}>
                <DataDownloadPage />
              </Route>

              <PrivateRoute fallback={PATHS.ROOT}>
                {/* To ensure backwards compatibility */}
                <Route path={PATHS.DATASETS_BETA_FOLDER_FILES}>
                  <DataManagementPage />
                </Route>
                <Route exact path={PATHS.DATASETS_BETA_FOLDERS}>
                  <DataManagementPage />
                </Route>

                <Route path={PATHS.DATASETS_FOLDER_FILES}>
                  <DataManagementPage />
                </Route>
                <Route exact path={PATHS.DATASETS_FOLDERS}>
                  <DataManagementPage />
                </Route>

                <Route exact path={PATHS.DATASETS_ROOT}>
                  <Redirect to={PATHS.DATASETS_FOLDERS} />
                </Route>
              </PrivateRoute>
            </Switch>

            <DownloaderV2 />

            <ToastRoot className="z-1" size="l" />
          </BrowserRouter>
        </SplitFactory>
      </QueryClientProvider>
    </Provider>
  );
};
