import { MoreMenu } from '@/components/MoreMenu';
import { canPolicyActionEdit } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import {
  DeleteSceneEntityGroupMutationVariables,
  RenameSceneEntityMutationVariables,
} from '@/graphql/codegen/graphql';
import { DELETE_SCENE_ENTITY_GROUP, RENAME_SCENE_ENTITY } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { useFetchSceneEntityPermissions } from '@/hooks/useFetchSceneEntityPermissions';
import {
  ConfirmationModalState,
  SetOpenConfirmationModal,
} from '@/pages/ProjectPage/DeleteConfirmationModal';
import { useExplore } from '@/stores/explore';
import {
  Annotation,
  AnnotationGroup,
  Layer,
  LayerGroup,
  PhotoGroup,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Graph } from '@skand/math';
import { Button, Menu, MenuItem, toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { ListItemButton } from './ListItemButton';
export interface LayerGroupNodeProps {
  group: LayerGroup;
  setLinkNodeTarget: (group: LayerGroup | null) => void;
  openConfirmationModal: ConfirmationModalState;
  setOpenConfirmationModal: SetOpenConfirmationModal;
  setEditNodeTarget: (node: LayerGroup | null) => void;
  editNodeTarget: LayerGroup | null;
  childCount: number;
}

export const LayerGroupNode = ({
  group,
  setLinkNodeTarget,
  setOpenConfirmationModal,
  setEditNodeTarget,
  editNodeTarget,
  childCount,
}: LayerGroupNodeProps) => {
  const projectId = useExplore(state => state.projectId);

  const layers = useViewer(state => state.layers);
  const layerGroups = useViewer(state => state.layerGroups);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);

  const visibleLayers = useViewer(state => state.visibleLayers);
  const visibleAnnotations = useViewer(state => state.visibleAnnotations);
  const visiblePhotoGroups = useViewer(state => state.visiblePhotoGroups);

  const enabledSelectMode = useViewer(state => state.enabledSelectMode);

  const { getSceneEntityPermission } = useFetchSceneEntityPermissions();
  const permission = getSceneEntityPermission(group.sceneEntityId);
  const canEdit = canPolicyActionEdit(permission);

  const [editInput, setEditInput] = useState(group.name);

  // Compute the all layers rooted at this group
  const [members, annotations, photoGroups] = useMemo(() => {
    // Construct the graph
    const entities = [
      ...layerGroups,
      ...layers,
      ...annotationGroups,
      ...photo2DGroups,
      ...panoramaGroups,
    ];
    const graph = new Graph<Layer | LayerGroup | AnnotationGroup | PhotoGroup>();
    for (const entity of entities) {
      graph.addNode(entity);
      if (entity.parent) {
        graph.addEdge(entity.parent, entity);
      }
    }

    // Traverse the graph
    const members: (Layer | LayerGroup)[] = [];
    const annotations: Annotation[] = [];
    const photoGroups: PhotoGroup[] = [];

    graph.bfs(group, node => {
      switch (node.type) {
        case 'layer':
        case 'layerGroup':
          members.push(node);
          break;
        case 'annotationGroup':
          annotations.push(...node.annotations);
          break;
        case 'photoGroup':
          photoGroups.push(node);
          break;
      }
    });

    return [members, annotations, photoGroups];
  }, [layerGroups, layers, annotationGroups, photo2DGroups, panoramaGroups, group]);

  const isVisible =
    members.some(layer => visibleLayers.has(layer.id)) ||
    annotations.some(annotation => visibleAnnotations.has(annotation.id)) ||
    photoGroups.some(group => visiblePhotoGroups.has(group.id));

  // Update layer group mutation
  const updateGroup = useMutation({
    mutationFn: (variables: RenameSceneEntityMutationVariables) =>
      request(RENAME_SCENE_ENTITY, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: 'Successfully updated layer group node.',
        lifespan: 5000,
      });
    },
  });

  // Delete layer group mutation
  const deleteGroup = useMutation({
    mutationFn: (variables: DeleteSceneEntityGroupMutationVariables) =>
      request(DELETE_SCENE_ENTITY_GROUP, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: 'Successfully deleted layer group node.',
        lifespan: 5000,
      });
    },
  });

  // Handle keyboard input while renaming the layer group
  const handleInputKeypress = (key: string) => {
    if (key === 'Enter') {
      handleSubmitName();
    }
    if (key === 'Escape') {
      setEditNodeTarget(null);
    }
  };

  // Handle renaming the layer
  const handleSubmitName = async () => {
    if (!projectId) return;
    await updateGroup.mutateAsync({
      sceneEntityId: group.sceneEntityId,
      projectId,
      name: editInput,
    });
    setEditNodeTarget(null);
  };

  // Handle deleting the layer group
  const handleDeleteLayerGroupNode = () => {
    if (!projectId) return;
    setOpenConfirmationModal({
      isOpen: true,
      title: 'Delete Layer Group',
      description: `Are you sure you want to delete the layer group ${group.name}?`,
      actionButton: 'Delete Layer Group',
      actionFunction: async () => {
        await deleteGroup.mutateAsync({
          sceneEntityId: group.sceneEntityId,
          projectId,
        });
        setOpenConfirmationModal((state: ConfirmationModalState) => {
          return { ...state, isOpen: false };
        });
      },
    });
  };

  // Handle toggling the layer group and its descendants
  const handleToggle = () => {
    if (isVisible) {
      useViewer.setState(prev => {
        const visibleLayers = new Set(prev.visibleLayers);
        const visibleAnnotations = new Set(prev.visibleAnnotations);
        const visiblePhotoGroups = new Set(prev.visiblePhotoGroups);
        for (const layer of members) {
          visibleLayers.delete(layer.id);
        }
        for (const annotation of annotations) {
          visibleAnnotations.delete(annotation.id);
        }
        for (const group of photoGroups) {
          visiblePhotoGroups.delete(group.id);
        }
        return { visibleLayers, visibleAnnotations, visiblePhotoGroups };
      });
    } else {
      useViewer.setState(prev => {
        const visibleLayers = new Set(prev.visibleLayers);
        const visibleAnnotations = new Set(prev.visibleAnnotations);
        const visiblePhotoGroups = new Set(prev.visiblePhotoGroups);
        for (const layer of members) {
          visibleLayers.add(layer.id);
        }
        for (const annotation of annotations) {
          visibleAnnotations.add(annotation.id);
        }
        for (const group of photoGroups) {
          visiblePhotoGroups.add(group.id);
        }
        return { visibleLayers, visibleAnnotations, visiblePhotoGroups };
      });
    }
  };

  return (
    <>
      {editNodeTarget === group ? (
        <div className="flex flex-1">
          <input
            className={cn(
              'px-1',
              'color-neutral-800',
              'typo-text-s-em',
              'rounded',
              'border-1',
              'border-solid',
              'border-primary-400',
              'outline-none',
              'w-full',
            )}
            onChange={e => setEditInput(e.target.value)}
            onKeyDown={e => handleInputKeypress(e.key)}
            value={editInput}
          />
          <Button className="ml-1" filled onClick={handleSubmitName} primary size="xs">
            Save
          </Button>
        </div>
      ) : (
        <>
          <div className="i-skand-layers w-5 text-3 color-neutral-600" />
          <p
            className={cn(
              'group',
              'cursor-pointer',
              'typo-text-small',
              'text-neutral-800',
              'flex-1',
              'whitespace-nowrap',
            )}
            onDoubleClick={() => {
              if (canEdit) {
                setEditInput(group.name);
                setEditNodeTarget(group);
              }
            }}
            title={group.name}
          >
            {group.name}
          </p>
          <div
            className={cn(
              'bg-neutral-200 py-[1px] px-1 rounded-[50px] min-w-5 h-[13px] justify-center flex items-center ',
            )}
          >
            <p className={cn('typo-text-xs-em text-neutral-500 whitespace-nowrap pt-[1px]')}>
              {childCount}
            </p>
          </div>
        </>
      )}

      <div className="w-48px" />

      <div
        className={cn(
          'fixed right-0 h-32px flex flex-none items-center gap-2 pl-2',
          !enabledSelectMode && 'bg-neutral-100',
        )}
        style={{
          boxShadow: !enabledSelectMode ? '-8px 0px 8px -2px rgba(255,255,255,1)' : 'none',
        }}
      >
        <ListItemButton
          icon={<div className={cn('i-skand-eye', isVisible ? 'i-skand-show' : 'i-skand-hide')} />}
          onClick={handleToggle}
        />
        <MoreMenu
          className={cn(
            canPolicyActionEdit(permission) ? 'cursor-pointer' : 'opacity-0',
            enabledSelectMode && 'hidden',
          )}
        >
          {canPolicyActionEdit(permission) && (
            <Menu className="z-2">
              <MenuItem className="cursor-pointer" onClick={() => setLinkNodeTarget(group)}>
                Link to layer
              </MenuItem>
              <MenuItem
                className="cursor-pointer"
                disabled={members.length > 1 || annotations.length > 0}
                onClick={handleDeleteLayerGroupNode}
              >
                Delete
              </MenuItem>
            </Menu>
          )}
        </MoreMenu>
      </div>
    </>
  );
};
