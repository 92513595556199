/**
 * @deprecated this utility is inaccurate, it uses 1024 as base, but uses decimal units (KB instead of KiB). Use formatBinaryBytes or formatDecimalBytes instead.
 */
const formatBytes = (bytes: number | null | undefined, decimals = 2) => {
  if (!bytes || bytes === 0)
    return {
      amount: 0,
      unit: 'Bytes',
    };

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const indexOfUnit = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    amount: (bytes / k ** indexOfUnit).toFixed(dm),
    unit: units[indexOfUnit],
  };
};

export const formatBinaryBytes = (bytes: number | null | undefined, decimals = 2) => {
  if (!bytes || bytes === 0)
    return {
      amount: 0,
      unit: 'Bytes',
    };

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const units = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const indexOfUnit = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    amount: (bytes / k ** indexOfUnit).toFixed(dm),
    unit: units[indexOfUnit],
  };
};

export const formatDecimalBytes = (bytes: number | null | undefined, decimals = 2) => {
  if (!bytes || bytes === 0)
    return {
      amount: 0,
      unit: 'Bytes',
    };

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const indexOfUnit = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    amount: (bytes / k ** indexOfUnit).toFixed(dm),
    unit: units[indexOfUnit],
  };
};

export default formatBytes;
